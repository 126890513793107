import { useComposableApi } from '@composable-api-config'

export default defineNuxtPlugin({
    name: 'setup',
    parallel: true,
    dependsOn: ['properties'],
    setup: (nuxtApp) => {
        const api = useComposableApi()

        const { currency } = useProperties()

        // TODO: make values reactive
        // @ts-ignore
        api.setHeader('Language-Preference', nuxtApp.$i18n.locale)
        api.setHeader('Currency-Preference', currency.value as any)


    },
})
