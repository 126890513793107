import { ApiResponseError } from '@composable-api/api.response-error'
import { useFetchAndSetMe, refreshSession, useDeleteAuthCookies, useSetAuthCookies } from '@core-theme/server/utils/auth'
import { getResponseHeaders, getCookie } from 'h3'
import { performanceCheckpoint } from '@core-theme/app/utils/performance'

export default defineNuxtPlugin({
    name: 'auth',
    parallel: true,
    dependsOn: ['setup'],
    setup: async (nuxtApp) => {
        // skip auth check when rendering an error page
        if (nuxtApp.payload.error) return

        const authStore = useAuthStore()

        if (import.meta.server) {
            performanceCheckpoint(useRequestEvent()!, 'auth')
            // ------------------- SERVER SIDE -------------------
            // responsible for:
            // - getting customer details on the initial server request
            // - setting the customer details in the auth store
            const requestHeaders = useRequestHeaders(['cookie'])
            const event = useRequestEvent()

            try {
            // ................ GET CUSTOMER DETAILS ON THE INITIAL SERVER REQUEST .................
                const isGuest = await useFetchAndSetMe(requestHeaders)

                // if the user is a guest & the refresh token is present (probably the session expired)
                if (isGuest && getCookie(event!, useRuntimeConfig().public.refreshTokenCookieName as string)) {
                    try {
                    // ........................... REFRESH THE SESSION .........................
                        await refreshSession(event!, requestHeaders)

                        // .............. GET CUSTOMER DETAILS AFTER SESSION REFRESH ...............
                        let newCookies = getResponseHeaders(event!)['set-cookie']
                        if (!newCookies) throw new Error('No cookies were set in the response')
                        newCookies = Array.isArray(newCookies) ? newCookies : [newCookies]

                        await useFetchAndSetMe({ cookie: newCookies.join(';') })
                    } catch (e) {
                    // .......... GETTING CUSTOMER DETAILS AFTER SESSION REFRESH FAILED ..........
                    //                               (log out)
                        useDeleteAuthCookies(event)
                    }

                }

            } catch (e) {
            // .............. THERE WAS AN API ERROR ..............
                if (e instanceof ApiResponseError) {
                // log out the user if the request failed for any reason
                    useDeleteAuthCookies(event)
                }

                // .............. THERE WAS A JAVASCRIPT ERROR ...............
                console.error('[ERROR] auth.server.ts:', e)
            }

            performanceCheckpoint(useRequestEvent()!, 'auth')
        } else if (import.meta.client) {
        // ------------------- CLIENT SIDE -------------------
        // responsible for:
        // - redirecting the user to the login page when they logged out
        //   while being on a page that requires auth
        //   (to prevent them from accessing the page while not being logged in)
            const currentRoute = useRoute()
            const redirectToPath = useStateRedirectToPath()
            const localePath = useLocalePath()

            watch(() => authStore.isLoggedIn, async (isLoggedIn) => {
                if (!isLoggedIn && doesRouteRequireAuth(currentRoute)) {
                // .............. USER SIGNED OUT WHILE BEING ON A PAGE THAT REQUIRES AUTH ...............
                // save the route to redirect to
                    redirectToPath.value = currentRoute.fullPath
                    // navigate to the login page
                    await navigateTo(useThemePath('login'))
                } else if (isLoggedIn && useIsThemePath('login')) {
                // ................... USER SIGNED IN WHILE BEING ON THE LOGIN PAGE ......................
                // get the path to redirect to
                    const pathToRedirectTo = redirectToPath.value || localePath('/')
                    // navigate to the previous page that required auth or to the home page
                    await navigateTo(pathToRedirectTo)
                }
            })
        }
    },
})
