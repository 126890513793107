import {
    type MaybeRefOrGetter,
    computed,
    toValue,
    type ComputedRef,
    getCurrentScope
} from 'vue'
import type { MaybePromise } from 'rollup'
import { useFetch, useRequestEvent } from 'nuxt/app'
import type { ApiModel } from '../api.model'
import { ApiResponse, type ApiResponseMeta } from '../api.response'
import { ApiResponseError } from '../api.response-error'
import { ApiReactiveUrlBuilder } from '../url-builder/api.reactive-url-builder'
import type { ConstructorType } from '../../types/utils'
import {
    apiFetch,
    type ApiServiceFetchOptions,
    getOnRequestInterceptor,
    type ServiceFetchBaseOptions,
    transformResponse,
    refreshSession
} from './api.service'
import { useStateHeaders } from '../../private/state'
import { useIsFetchingFromSubdomain } from '../../composables/url'
import { joinURL } from 'ufo'
import { useConfig } from '../../private/config'
import { H3Event } from 'h3'
import type { ServerCache, ApiRoutePrefixes } from '../../types/module'
import { useServerCache } from '../../composables/caching/caching'
import { FetchError, type FetchOptions } from 'ofetch'
import type { _AsyncData } from '#app/composables/asyncData'

type AdditionalData<T extends ApiModel, Accumulate extends boolean> = {
    builderData: ReturnType<ApiReactiveUrlBuilder<T>['captureBuilderDataAndReset']>
    item: ComputedRef<(DefaultTo<Accumulate, false> extends true ? T[] : T) | null>
    items: ComputedRef<T[]>
}

type CustomAsyncData<Data, Error, Accumulate extends boolean> = _AsyncData<Data, Error> & AdditionalData<Data extends ApiResponse<infer M> ? M : never, Accumulate>
    & Promise<_AsyncData<Data, Error> & AdditionalData<Data extends ApiResponse<infer M> ? M : never, Accumulate>>

type DefaultTo<T, U> = T extends undefined ? U : T

type ApiServiceOptions = ({
    url: MaybeRefOrGetter<string>
    endpoint?: never
} | {
    url?: never
    endpoint: MaybeRefOrGetter<string>
}) & {
    routePrefix?: MaybeRefOrGetter<keyof ApiRoutePrefixes>
}

export type ApiServiceUseFetchOptions<T extends ApiModel, A extends boolean> = Partial<ServiceUseFetchOptions<T, A>>

interface SSRCacheOptions {
    key: keyof ServerCache
    /**
     * The time in seconds after which the cache entry should expire.
     */
    ttl?: number | null
}

type ServiceUseFetchOptions<T extends ApiModel, A extends boolean = false> = {
    /**
     * Whether the request should be made immediately.
     *
     * @default true
     */
    immediate: boolean
    /**
     * Whether the request should automatically re-fetch when the `watch` dependencies change.
     *
     * @default true
     */
    watch: boolean
    /**
     * Whether the request should be made on the server-side.
     *
     * @default true
     */
    server: boolean
    /**
     * Whether the request should be lazy (not blocking navigation).
     * For true lazy requests (not blocking even the first render), use `server: false`
     * in combination with `lazy: true`.
     *
     * @default false
     */
    lazy: boolean
    /**
     * The cache key to use for the request in server-side cache.
     * If not provided, the request will not be cached.
     *
     * An object with options can be provided instead of a single key to
     * set additional settings for the cache entry like TTL, for example.
     *
     * To add cache keys, define them in an `index.d.ts` file for the following interface:
     * @example Adding cache keys
     * declare module '@composable-api-types/module' {
     *     interface ServerCache {
     *         'products': ApiResponse<ProductModel>
     *     }
     * }
     *
     * @default undefined
     */
    ssrCache: keyof ServerCache | SSRCacheOptions
    /**
     * Whether subsequent requests should be accumulated with the previous ones.
     * This doesn't override the previous response data, but appends new data to it.
     * This is useful for paginated requests (when implementing infinite scrolling, for example).
     */
    accumulate: A
    /**
     * A function to call after the response is received.
     * This is useful in combination with reactive api calls. (e.g. refreshing the data
     * & assigning it to a reactive variable on response)
     * @param response The response of the API call.
     */
    onResponse: (response: ApiResponse<T>, accumulated: ApiResponse<T>[]) => MaybePromise<void>
}


export class ApiReactiveService<T extends ApiModel> extends ApiReactiveUrlBuilder<T> {
    private readonly model: ConstructorType<T> | null = null

    constructor(options: ApiServiceOptions, model: ConstructorType<T> | null) {
        if (!getCurrentScope()) {
            throw new Error('Reactive ApiService can only be used within the script setup block of a component. ' +
                'You can use the non-reactive ApiService in other places.')
        }

        // capture the values into separate variables because `useConfig()` cannot be passed into
        // the `super()` call directly, because it is a composable and that would make the reactive
        // api url builder not usable in places where the Nuxt instance is not available
        const config = useConfig()
        const apiUrl = config.apiUrl ?? ''
        const apiRoutePrefix = toValue(options.routePrefix) ?? config.apiRoutePrefix ?? ''


        super({
            url: () =>
                toValue(options.url) ??
                joinURL(
                    apiUrl,
                    apiRoutePrefix,
                    toValue(options.endpoint) ?? ''
                ),
        })

        this.model = model
    }

    // AsyncData<PickFrom<DataT, PickKeys> | DefaultT, ErrorT | DefaultAsyncDataErrorValue>

    protected useFetch<A extends boolean>(options?: Partial<ApiServiceUseFetchOptions<T, A> & ServiceFetchBaseOptions<any>>): CustomAsyncData<ApiResponse<T> | undefined, FetchError<any> | null, A> {
        if (import.meta.dev && options?.ssrCache && (options.server === false || (options.method && options.method.toUpperCase() !== 'GET'))) {
            console.warn('[useFetch]: The `ssrCache` option only makes sense with server-side GET requests.')
        }
        // ------------------------------------------------------------------------------
        const builderData = this.captureBuilderDataAndReset()

        let responseMeta: Omit<ApiResponseMeta, 'body'> | null = null

        // TODO: document these headers & add an option to react to headers change
        const headers = useStateHeaders()

        const _isFetchingFromSubdomain = useIsFetchingFromSubdomain(builderData.builtUrl)
        const credentialsOption = computed(() => _isFetchingFromSubdomain.value ? 'include' : undefined)
        let hasRetriedAfterUnauthorized = false

        const { getCacheUrl, setData } = useServerCache()

        const cacheKey = typeof options?.ssrCache === 'object'
            ? options.ssrCache.key as string
            : options?.ssrCache as string | undefined

        const url = computed(() =>
            options?.ssrCache
            && (options.method === undefined || options.method.toUpperCase() === 'GET')
            && options.server !== false
                ? getCacheUrl(cacheKey as keyof ServerCache)
                : toValue(builderData.builtUrl)
        )

        const authTokenCookieName = useConfig().authTokenCookieName

        const interceptResponse: FetchOptions['onResponse'] = ({ response, error }) => {
            responseMeta = {
                status: response.status,
                headers: JSON.parse(JSON.stringify(response.headers)),
            }
        }

        let event: H3Event | undefined
        let isFetchingFromSubdomain: boolean

        const asyncData = useFetch(url, {
            params: builderData.builtParams,
            onRequest: (context) => {
                event = useRequestEvent()
                isFetchingFromSubdomain = _isFetchingFromSubdomain.value

                // Do not use the cached data if the request was made on the client
                if (options?.ssrCache && import.meta.client) {
                    context.request = builderData.builtUrl.value
                }

                return getOnRequestInterceptor(
                    event,
                    isFetchingFromSubdomain,
                    authTokenCookieName,
                    headers.value as Record<string, string>
                )(context)
            },
            onResponse: interceptResponse,
            onResponseError: async ({ response }) => {
                if (response.status !== 401 || hasRetriedAfterUnauthorized) {
                    // TODO: Add support for ApiResponseError
                    // throw transformError(response)
                    return
                }

                hasRetriedAfterUnauthorized = true

                await refreshSession(
                    event,
                    isFetchingFromSubdomain,
                    authTokenCookieName
                )

                // Retry the request
                await asyncData.refresh()
            },
            transform: async (data) => {
                let _data = data
                // Replace the data with the data from the cache, if applicable
                // (should use cache, is on the server & the cache has no data)
                if (options?.ssrCache && import.meta.server && responseMeta?.status === 204) {
                    try {
                        const executeFetch = () => $fetch(builderData.builtUrl.value, {
                            params: builderData.builtParams.value,
                            headers: headers.value as Record<string, string>,
                            onRequest: getOnRequestInterceptor(
                                event,
                                isFetchingFromSubdomain,
                                authTokenCookieName,
                                headers.value as Record<string, string>
                            ),
                            onResponse: interceptResponse,
                            onResponseError: async ({ response }) => {
                                if (response.status !== 401 || hasRetriedAfterUnauthorized) {
                                    // TODO: Add support for ApiResponseError
                                    // throw transformError(response)
                                    return
                                }

                                hasRetriedAfterUnauthorized = true

                                await refreshSession(
                                    event,
                                    isFetchingFromSubdomain,
                                    authTokenCookieName
                                )

                                _data = await executeFetch()
                            },
                        })

                        // fetch data from original url
                        _data = await executeFetch()

                        const cacheOptions = (typeof options.ssrCache === 'object' ? options.ssrCache : undefined) as SSRCacheOptions | undefined

                        // set the data to the cache
                        if (_data) {
                            await setData(cacheKey as keyof ServerCache, _data as never, {
                                ttl: cacheOptions?.ttl,
                            })
                        }
                    } catch (e) {
                        if (e instanceof ApiResponseError) {
                            throw e
                        }
                        console.error('[useFetch] Error fetching data from original url for cache', e)
                    }
                }

                /*
                // TODO: in the future, we might want to cache the response based on query params too
                else if (options?.ssrCache && import.meta.client) {
                    console.log('FETCHING ON THE CLIENT!')
                }
                 */

                const transformedResponse = transformResponse(this.model, _data, responseMeta)

                if (options?.accumulate) {
                    if (Array.isArray(asyncData.data.value)) {
                        const accumulatedResponses = asyncData.data.value as ApiResponse<T>[]
                        accumulatedResponses.push(transformedResponse)
                        options?.onResponse?.(transformedResponse, accumulatedResponses)
                        return accumulatedResponses as unknown as ApiResponse<T>    // TODO: replace later with correct generic typing
                    }
                    return [transformedResponse] as unknown as ApiResponse<T>       // TODO: replace later with correct generic typing
                }

                options?.onResponse?.(transformedResponse, [])
                return transformedResponse
            },
            immediate: options?.immediate,
            watch: options?.watch === false ? false : [builderData.builtUrl, ...(options?.watch as unknown as any[] || [])],
            server: options?.server,
            lazy: options?.lazy,
            method: options?.method,
            body: options?.body,
            credentials: credentialsOption,
        })

        let item: ComputedRef<(DefaultTo<A, false> extends true ? T[] : T) | null>
        let items: ComputedRef<T[]>

        const additionalData = {
            builderData,
            /**
             * The model from the ApiResponse.
             * This computed property holds the result of `ApiResponse.getItem()`.
             *
             * When used with `accumulate: true`, this will be an array of models.
             */
            get item() {
                if (!item) {
                    item = computed(() => options?.accumulate
                        ? (asyncData.data.value as ApiResponse<T>[] | undefined)?.map(response => response.getItem()) ?? []
                        : (asyncData.data.value as ApiResponse<T> | undefined)?.getItem() ?? null
                    ) as any
                }
                return item
            },
            /**
             * An array of the models from the ApiResponse.
             * This computed property holds the result of `ApiResponse.getItems()`.
             *
             * When used with `accumulate: true`, this will be a flattened array of arrays of models.
             */
            get items() {
                if (!items) {
                    items = computed(() => options?.accumulate
                        ? (asyncData.data.value as ApiResponse<T>[] | undefined)?.flatMap(response => response.getItems()) ?? []
                        : (asyncData.data.value as ApiResponse<T> | undefined)?.getItems() ?? []
                    )
                }
                return items
            },
        }

        const asyncDataPromise =
            Promise.resolve(asyncData).then(data => Object.assign(data, additionalData))
        Object.assign(asyncDataPromise, asyncData, additionalData)
        return asyncDataPromise as unknown as (typeof asyncData & typeof additionalData) & typeof asyncDataPromise
    }

    // -----------------------------------------------------------------------------------------------------------------

    protected async fetch(options?: Partial<ApiServiceFetchOptions<T> & ServiceFetchBaseOptions<any>>) {
        // TODO: dispose of effects
        const builderData = this.captureBuilderDataAndReset()

        const isFetchingFromSubdomain = useIsFetchingFromSubdomain(builderData.builtUrl)
        const authTokenCookieName = useConfig().authTokenCookieName

        // TODO: document these headers & add an option to react to headers change
        const headers = useStateHeaders()

        const response = await apiFetch({
            model: this.model,
            url: builderData.builtUrl.value,
            options: options,
            event: useRequestEvent(),
            isFetchingFromSubdomain: isFetchingFromSubdomain.value,
            authTokenCookieName: authTokenCookieName,
            headers: headers.value as Record<string, string>,
            params: builderData.builtParams.value,
        })

        this.cleanupBuilderData(builderData)

        return response
    }

}


