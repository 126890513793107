import { ApiService, type ApiServiceFetchOptions } from '@composable-api/service/api.service'
import type { ApiModel, ApiModelAttributes } from '@composable-api/api.model'
import { CartItemModel } from '../models/cart-item.model'

class CartItemsApiService<M extends ApiModel> extends ApiService<M> {
    get(options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'GET',
        })
    }

    post(data: CartItemPayload, options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'POST',
            body: data,
        })
    }

    patch(data: Partial<ApiModelAttributes<CartItemModel>>, options?: ApiServiceFetchOptions<M>) {
        return this
            .fetch({
                ...options,
                method: 'PATCH',
                body: data,
            })
    }

    delete(options?: ApiServiceFetchOptions<M>) {
        return this
            .fetch({
                ...options,
                method: 'DELETE',
            })
    }
}

export function getCartItemsApiService(options: {
    cartId: string
}) {
    return new CartItemsApiService(
        {
            endpoint: `/carts/${options.cartId}/items`,
        },
        CartItemModel
    )
}

export interface CartItemPayload {
    // needs to be 1 or more, or, when `null`, the default minimum amount will be used
    [CartItemModel.ATTR_AMOUNT]: ApiModelAttributes<CartItemModel>['amount']
    [CartItemModel.ATTR_PRODUCT_ID]: ApiModelAttributes<CartItemModel>['product_id']
    [CartItemModel.ATTR_PRODUCT_VARIATION_ID]?: ApiModelAttributes<CartItemModel>['product_variation_id']
    [CartItemModel.ATTR_PARENT_ID]?: ApiModelAttributes<CartItemModel>['parent_id']
    [CartItemModel.ATTR_PAYLOAD]?: ApiModelAttributes<CartItemModel>['payload']
}
