export default defineNuxtRouteMiddleware((to, from) => {
    // skip middleware on initial client load
    const nuxtApp = useNuxtApp()
    if (import.meta.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) return

    const authStore = useAuthStore()    // client side logged in check
    // redirect path to use after logging in
    const redirectTo = useStateRedirectToPath()

    // if the user tries to access a page that is ONLY FOR GUESTS while being LOGGED IN
    if (isRouteOnlyForGuests(to) && authStore.isLoggedIn) {
        // redirect the user to the home page
        return navigateTo(useLocalePath()('/'))
    }

    // if the user tries to access a PROTECTED PAGE while NOT BEING LOGGED IN
    // TODO: when adding support for permissions, `doesRouteRequireAuth` and this condition will need to be adjusted
    if (!authStore.isLoggedIn && doesRouteRequireAuth(to)) {
        // save the route to redirect to, so that we can redirect the user to it after they log in
        redirectTo.value = to.fullPath

        // TODO: add reason, show custom error page (or go to the login page?)
        return navigateTo(useThemePath('login'))
    }

    // every time the user navigates AWAY FROM THE LOGIN PAGE, reset the redirect to path
    if (useIsThemePath('login', from)) {
        // reset the redirect to path
        useStateRedirectToPath().value = undefined
    }
})
