<script lang="tsx">
import { Transition } from 'vue'
import { IconCrossCircleSolid, IconEye, IconEyeCrossed } from '#components'
import { defineComponentBaseUiInput } from '@core/app/components/base/ui/BaseUiInput.vue'
import type { SizeProp } from '@core/types/components'

export type BaseUiInputColors = 'white' | 'gray' | 'primary'
type Variants = 'solid' | 'inline'
type Sizes = SizeProp | 'none'

export default defineComponentBaseUiInput<BaseUiInputColors, Variants, Sizes>({
    props: {
        size: {
            default: 'md',
        },
        color: {
            default: 'white',
        },
        variant: {
            default: 'solid',
        },
    },
    slots: {
        clear: () => <IconCrossCircleSolid size="md" />,
        passwordToggle: ({ isVisible }) => <Transition mode="out-in">
            {isVisible ? <IconEyeCrossed class="icon" /> : <IconEye size={22} class="icon" />}
        </Transition>,
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiInput" as *;

@include input {
    border-radius: $sim-border-radius-sm;

    font-weight: 400;

    @include set-item-gap(0.625rem);
}

@include placeholder {
    @include sim-text-small;
    @include sim-font-regular;
}

@include side-content {
    color: $sim-c-primary-900;
}

@include clear-button {
    border-radius: 100vmax;
    padding: 0.25rem;
    margin: -0.75rem -0.25rem;
}

.icon {
    &.v-enter-active,
    &.v-leave-active {
        transition: transform 75ms $sim-timing;
    }

    &.v-enter-from,
    &.v-leave-to {
        transform: scale(0.85);
    }
}

@include password-toggle-button {
    border-radius: 100vmax;
    width: 2rem;
    height: 2rem;
    padding: 0.25rem;
    margin: -0.25rem;
    margin-block: -0.5rem;

    transition: color $sim-trans-time-short $sim-timing;

    &:hover {
        color: $sim-c-simplo;
    }
}

@include input--variant('inline') {
    background: transparent;
    border-radius: initial;
    border-bottom: 1px dashed $sim-c-basic-600;

    &:focus-within {
        border-bottom: 1px solid $sim-c-primary-800;
    }

    @include placeholder {
        color: $sim-c-basic-800;
    }

    @include spin-buttons {
        appearance: none;
        margin: 0;
    }
}

@include input--color('white') {
    @include input--variant('solid', '&') {
        background-color: $sim-c-white;
        color: $sim-c-primary-900;

        &:focus-within {
            outline: 1px solid $sim-c-primary-900;
        }

        @include placeholder {
            color: $sim-c-basic-800;
        }
    }
}

@include input--color('gray') {
    @include input--variant('solid', '&') {
        background-color: $sim-c-basic-200;
        color: $sim-c-primary-900;

        &:focus-within {
            outline: 1px solid $sim-c-primary-900;
        }

        @include placeholder {
            color: $sim-c-basic-800;
        }
    }
}

@include input--color('primary') {
    @include input--variant('solid', '&') {
        background-color: $sim-c-primary-100;
        color: $sim-c-primary-900;

        &:focus-within {
            outline: 1px solid $sim-c-primary-900;
        }

        @include placeholder {
            color: $sim-c-primary-600;
        }
    }
}

@include input--error {
    color: $sim-c-danger-700;
    outline: 1px solid $sim-c-danger-700;
    background-color: $sim-c-white;

    &:focus-within {
        outline: 2px solid $sim-c-danger-700;
    }
}

// --------------------------------------------------------------------
// Input sizes

@include input--size('sm') {
    @include set-padding(0.625rem, 0.75rem);

    @include input--square('&') {
        @include set-padding(0.625rem, 0.625rem);
    }
}

@include input--size('md') {
    @include set-padding(0.85rem, 1.5rem);

    @include input--square('&') {
        @include set-padding(0.85rem, 0.85rem);
    }
}

@include input--size('none') {
    @include set-padding(0, 0);
}

</style>
