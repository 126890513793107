export function useAuth() {
    const store = useAuthStore()
    const { customer: storeCustomer, _me: storeMe, isLoggedIn: storeIsLoggedIn } = storeToRefs(store)
    const { $i18n } = useNuxtApp()
    const events = useEvents()

    let passwordResetToken: ComputedRef<string | null>

    /**
     * Log in the customer with the provided credentials.
     * @param credentials The email and password of the customer
     * @throws FetchError if the login request fails
     * @todo Change FetchError to ApiResponseError
     */
    async function logIn(credentials: { email: string, password: string }) {
        // get the cookie with the auth token etc.
        const authResponse = await $fetch('/api/auth/login', {
            method: 'POST',
            body: {
                email: credentials.email,
                password: credentials.password,
            },
        })

        // fetch me
        const meResponse = await getMeApiService().get()
        const me = meResponse.getItem()

        // if for some reason the 'Me' model could not be fetched, notify the user & return
        // the cookie should be set at this point so a simple page refresh should fix the issue
        if (!me) {
            useNotifyError($i18n.t('_core_simploshop.errors.login_failed'))
            return
        }

        // await all the logging-in callbacks
        await events.emit('customer:logging-in', { me })

        // visually log in the customer
        store.setCustomer(me)

        // emit the logged-in event to indicate that the login has been fully completed
        events.emit('customer:logged-in', { me })
    }

    /**
     * Register a new customer.
     * @param payload The customer's registration data
     * @throws ApiResponseError if the registration request fails
     */
    async function register(payload: {
        firstName: string
        lastName: string
        email: string
        password: string
        passwordConfirmation: string
        shouldSubscribeToNewsletter: boolean
    }) {
        await getCustomersApiService()
            .registerCustomer({
                first_name: payload.firstName,
                last_name: payload.lastName,
                email: payload.email,
                password: payload.password,
                password_confirmation: payload.passwordConfirmation,
                is_subscribed: payload.shouldSubscribeToNewsletter,
            })

        // await all the registering callbacks
        await events.emit('customer:registering', { credentials: { email: payload.email, password: payload.password } })

        // emit the registered event to indicate that the registration has been fully completed
        events.emit('customer:registered', { credentials: { email: payload.email, password: payload.password } })
    }

    /**
     * Log out the currently logged-in customer.
     * If no customer is not logged in, this function does nothing.
     *
     * Automatically notifies the user if the logout request fails.
     */
    async function logOut() {
        if (!storeIsLoggedIn.value) return

        try {
            await $fetch('/api/auth/logout', {
                method: 'POST',
            })

            // await all the logging-out callbacks
            await events.emit('customer:logging-out', {})

            // visually log out the customer
            store.removeCustomer()

            // emit the logged-out event to indicate that the logout has been fully completed
            events.emit('customer:logged-out', {})
        } catch (e) {
            console.error(e)

            useNotifyError()
        }
    }

    /**
     * Send password reset token for a customer account with the given email
     * @throws ApiResponseError if the send reset token request fails
     * @param payload the email of the customer account for which to reset the password
     */
    async function sendPasswordResetToken(payload: { email: string }) {
        await getCustomerPasswordsApiService().post({
            email: payload.email,
        })
    }

    /**
     * Reset the password of a customer with the given password reset token
     * @throws ApiResponseError if the reset password request fails
     * @param payload the password reset data
     */
    async function resetPassword(payload: { token: string, new_password: string, new_password_confirmation: string,  }) {
        await getCustomerPasswordsApiService().put({
            token: payload.token,
            new_password: payload.new_password,
            new_password_confirmation: payload.new_password_confirmation,
        })
    }

    return {
        me: storeMe,
        customer: storeCustomer,
        loggedIn: storeIsLoggedIn,
        logIn: logIn,
        logOut: logOut,
        register: register,
        sendPasswordResetToken: sendPasswordResetToken,
        resetPassword: resetPassword,

        get passwordResetToken() {
            if (!passwordResetToken) {
                passwordResetToken = computed(() => {
                    const route = useRoute()
                    return route.query.token ? (route.query.token as string) : null
                })
            }

            return passwordResetToken
        },
    }
}
