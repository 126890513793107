<script lang="ts">
import type { CoreIconSizes, Sizes } from '@core-types/components/CoreIcon'
import type { ComponentOverrideOptions } from '@core-types/components'
import { cloneVNode, type PropType, type VNode } from 'vue'
import type { ReturnType } from 'birpc'

export type ComponentTemplateOverride<T, D = unknown, S = unknown, E = unknown> = (data: { props: T, setup: D, slots: S, emit: E }) => VNode

export type CoreIconProps = {
    size?: CoreIconSizes | number
    width?: string | number
    height?: string | number
    left?: boolean
    right?: boolean
    up?: boolean
    down?: boolean
    rotate?: 'up' | 'down' | 'left' | 'right' | number
    ariaHidden?: boolean | 'true' | 'false'
    filled?: boolean
}

/**
 * Checks if the given size is a valid Size 'enum' value.
 * @param size The string to check.
 */
function isSize(size: string | undefined): size is Sizes {
    if (!size) return false
    const sizes: Sizes[] = ['xs', 'sm', 'md', 'lg', 'xl', '2xl']
    return sizes.includes(size as Sizes)
}

/**
 * Computes the size of the icon based on the given base size and the given size enum value.
 * @param base The base (default) size of the icon.
 * @param size The target size to convert the base size to. (e.g. 'lg', '2xl', ...)
 */
function getSize(base: number, size: Sizes) {
    // size mappings in rem
    const mappings: Record<Sizes, number> = {
        'xs': 1,
        'sm': 1.25,
        'md': 1.5, // default icon size is 1.5rem
        'lg': 2,
        'xl': 3,
        '2xl': 4,
    }
    return mappings[size] * base / mappings['md']
}


interface ParsedNumberWithUnit {
    number: number | null
    unit: string | null
}
function extractNumberAndUnit(input: string | number | undefined): ParsedNumberWithUnit {
    if (typeof input === 'undefined') return { number: null, unit: null }
    if (typeof input === 'number') return { number: input, unit: null } // px is fallback unit

    const matches = input.match(/^(\d*\.?\d+)([a-zA-Z%]+)?$/)

    if (!(matches?.[1])) return { number: null, unit: null }

    return {
        number: parseFloat(matches[1]),
        unit: matches[2] ?? null,
    }
}

export function defineComponentCoreIcon(iconTemplate: VNode | ComponentTemplateOverride<CoreIconProps>, options: ComponentOverrideOptions<{}, CoreIconProps> = {}) {
    return defineComponent({
        props: {
            size: {
                type: [String, Number] as PropType<CoreIconProps['size']>,
                default: options.props?.size ? undefined : 'md',
                required: false,
            },
            width: {
                type: [String, Number] as PropType<CoreIconProps['width']>,
                default: undefined,
                required: false,
            },
            height: {
                type: [String, Number] as PropType<CoreIconProps['height']>,
                default: undefined,
                required: false,
            },
            left: {
                type: Boolean as PropType<CoreIconProps['left']>,
                default: options.props?.left ? undefined : false,
                required: false,
            },
            right: {
                type: Boolean as PropType<CoreIconProps['right']>,
                default: options.props?.right ? undefined : false,
                required: false,
            },
            up: {
                type: Boolean as PropType<CoreIconProps['up']>,
                default: options.props?.up ? undefined : false,
                required: false,
            },
            down: {
                type: Boolean as PropType<CoreIconProps['down']>,
                default: options.props?.down ? undefined : false,
                required: false,
            },
            rotate: {
                type: [String, Number] as PropType<CoreIconProps['rotate']>,
                default: undefined,
                required: false,
            },
            ariaHidden: {
                type: [Boolean, String] as PropType<CoreIconProps['ariaHidden']>,
                default: undefined,
                required: false,
            },
            filled: {
                type: Boolean as PropType<CoreIconProps['filled']>,
                default: undefined,
                required: false,
            },
        },
        setup: (props, { slots, emit }) => {
            // ....................................................................
            /*
                COMPUTE PROPS OVERRIDE
                This should be used inside the component
                instead of the regular props object, because this computed property
                reflects the overridden default values for props.
             */
            // TODO: move to a composable at a later time
            const overriddenProps = computed<typeof props>(() => {
                const merged = { ...props }
                for (const key in options.props) {
                    if (props[key as keyof typeof props] === undefined) {
                        // @ts-ignore
                        merged[key] = options.props[key]
                    }
                }
                return merged
            })
            // ....................................................................


            // ---------------------------------------------------------------------------------------------------------
            // WIDTH & HEIGHT DIMENSIONS

            /**
             * Extracts the width and height from the icon template.
             * For each dimension, there is a number and a unit returned.
             * The unit can be null if it is not specified.
             *
             * @todo Seems like the computed property is not called when the icon template is a function. Fix later. However, for some reason, watchers work.
             */
            const iconTemplateSize = computed<{ width: ParsedNumberWithUnit | null, height: ParsedNumberWithUnit | null}>(() => {
                return {
                    width: extractNumberAndUnit(_iconTemplate.value.props?.width) ?? null,
                    height: extractNumberAndUnit(_iconTemplate.value.props?.height) ?? null,
                }
            })

            /**
             * Computes the dimensions of the new icon.
             */
            const iconDimensions = computed<{ width: string | undefined, height: string | undefined }>(() => {
                const BASE_SIZE_IN_REM = 1.5

                // TODO: add support for <img> element, which don't support rem
                function calculateDimension(dimension: 'width' | 'height') {
                    // EXPLICIT DIMENSION (e.g. `width="24px"`, `height="24px"`)
                    if (typeof overriddenProps.value[dimension] === 'number') return `${overriddenProps.value[dimension]}px`    // bare numbers are in px
                    if (typeof overriddenProps.value[dimension] === 'string') return overriddenProps.value[dimension] as string

                    // SIZE PROPERTY
                    if (typeof overriddenProps.value.size === 'number') return `${overriddenProps.value.size}px`    // bare numbers are in px

                    // size is a generic multiplier (e.g. 'md', 'lg', 'e:md', ...)
                    if (typeof overriddenProps.value.size === 'string') {
                        const templateDimension = iconTemplateSize.value[dimension]
                        const templateSize = templateDimension?.number
                        const templateUnit = templateDimension?.unit
                        const [emFlag, sizeValue] = overriddenProps.value.size.split(':')
                        const wantsEm = emFlag === 'e'
                        const normalizedSize = (wantsEm ? sizeValue : emFlag)

                        // return the size string as-is if it is not a valid size (could be '100%', for example)
                        if (!isSize(normalizedSize)) return overriddenProps.value.size

                        if (templateSize) {
                            const size = getSize(templateSize, normalizedSize)
                            const unit = wantsEm ? 'em' : (templateUnit ?? 'px')
                            const wantsEmAndIsRem = wantsEm && ['rem', 'em'].includes(`${templateUnit}`)
                            const factor = wantsEmAndIsRem ? 1 : wantsEm ? 16 : 1

                            return `${size / factor}${unit}`
                        }

                        return `${getSize(BASE_SIZE_IN_REM, normalizedSize)}rem`
                    }

                    return undefined
                }

                return {
                    width: calculateDimension('width'),
                    height: calculateDimension('height'),
                }
            })

            // ---------------------------------------------------------------------------------------------------------
            // TRANSFORM STYLES FOR ROTATIONS
            const transformStyles = computed<{ transform?: string }>(() => {
                if (typeof overriddenProps.value.rotate === 'number') {
                    return {
                        transform: `rotate(${overriddenProps.value.rotate}deg)`,
                    }
                }

                return {
                    transform: (
                        () => {
                            if (overriddenProps.value.rotate === 'left' || overriddenProps.value.left) return 'rotate(-90deg)'
                            if (overriddenProps.value.rotate === 'right' || overriddenProps.value.right) return 'rotate(90deg)'
                            if (overriddenProps.value.rotate === 'up' || overriddenProps.value.up) return 'rotate(-180deg)'
                            if (overriddenProps.value.rotate === 'down' || overriddenProps.value.down) return 'rotate(0deg)'
                        }
                    )(),
                }
            })

            // ---------------------------------------------------------------------------------------------------------

            // ensure that the styles of the component can be properly scoped
            // TODO: move to a utility function at a later time
            function getScopeId() {
                const currentInstance = getCurrentInstance()
                // @ts-ignore
                if (currentInstance?.vnode?.type?.__scopeId) {
                    return {
                        // @ts-ignore
                        [currentInstance.vnode.type.__scopeId]: '',
                    }
                }
                return {}
            }

            // ---------------------------------------------------------------------------------------------------------

            const _iconTemplate = computed<ReturnType<ComponentTemplateOverride<any>>>(
                () => typeof iconTemplate === 'function'
                    ? iconTemplate({
                        props: props,
                        setup: {

                        },
                        slots: slots,
                        emit: emit,
                    })
                    : iconTemplate

            )

            // ------------------------ RENDER ------------------------
            return () => cloneVNode(
                (
                    _iconTemplate.value
                ), {
                    // if the height is specified, but the width is not, do not set the width
                    'width': iconDimensions.value.width,
                    // if the width is specified, but the height is not, do not set the height
                    'height': iconDimensions.value.height,
                    'style': {
                        ...transformStyles.value,
                        'flex-shrink': '0',
                    },
                    'focusable': false,
                    'aria-hidden': overriddenProps.value.ariaHidden,
                    ...getScopeId(),
                }
            )
        },
    }) // as unknown as DefineComponent<CoreIconProps>
}

export default defineComponentCoreIcon(h('div'))

</script>

<style lang="scss" scoped>

</style>
