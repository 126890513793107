import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [CustomerProductListModel.ATTR_ID]: number
    [CustomerProductListModel.ATTR_CUSTOMER_ID]: number
    [CustomerProductListModel.ATTR_NAME]: string | null
    [CustomerProductListModel.ATTR_DESCRIPTION]: string | null
    [CustomerProductListModel.ATTR_TYPE]: number // TODO: enum CustomerProductListTypeEnum(WISH_LIST = 1, PRODUCTS_COMPARISON = 2)
    [CustomerProductListModel.ATTR_POSITION]: number
}

export class CustomerProductListModel extends ApiModel<Attributes> {
    static readonly ATTR_ID = 'id'
    static readonly ATTR_CUSTOMER_ID = 'customer_id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_DESCRIPTION = 'description'
    static readonly ATTR_TYPE = 'type'
    static readonly ATTR_POSITION = 'position'

    get id() {
        return this._getAttribute(CustomerProductListModel.ATTR_ID)
    }

    get type() {
        return this._getAttribute(CustomerProductListModel.ATTR_TYPE)
    }
}
