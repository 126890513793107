import validate from "/opt/simploshop/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._qmj4hv4ysdxaferpnceonlpv7a/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/opt/simploshop/shared/core-theme/app/middleware/auth.global.ts";
import manifest_45route_45rule from "/opt/simploshop/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._qmj4hv4ysdxaferpnceonlpv7a/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  cart: () => import("/opt/simploshop/shared/core-theme/app/middleware/cart.ts")
}