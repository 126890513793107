/**
 * A composable that allows you to lock the scroll of the body element.
 * !! Important: only to be used within <script setup> !!
 * @returns the `lock()` and `unlock()` functions used to manage the HTML Body scroll lock
 */
export default function () {
    const bodyElement = ref<HTMLBodyElement | null>(null)
    const scrollLock = useScrollLock(bodyElement)
    const popupsStore = usePopupsStore()

    /**
     * Locks the scroll of the body element.
     *
     * !! IMPORTANT !!
     * Should not be called manually unless you are absolutely sure that you know what you are doing.
     * (handled by `useManagePopupOpening` composable)
     *
     * @private
     * @see useManagePopupOpening
     * @returns the current state of the scroll lock (true if locked)
     */
    function _lock(): boolean {
        // sometimes, the onMounted hook isn't called soon enough and the body element is still null when
        // the lock function is called -> which would cause the scroll not to be locked
        // this is a failsafe for that
        if (!bodyElement.value) {
            bodyElement.value = document.body as HTMLBodyElement
        }
        // calculate the scrollbar width
        popupsStore._scrollBarWidth = window.innerWidth - document.documentElement.clientWidth

        scrollLock.value = true
        bodyElement.value!.style.paddingRight = popupsStore.paddingWhenScrollLocked
        return scrollLock.value
    }

    /**
     * Unlocks the scroll of the body element.
     *
     * !! IMPORTANT !!
     * Should not be called manually unless you are absolutely sure that you know what you are doing.
     * (handled by `useManagePopupOpening` composable)
     *
     * @private
     * @see useManagePopupOpening
     * @returns the current state of the scroll lock (false if unlocked)
     */
    function _unlock(): boolean {
        scrollLock.value = false
        // Temporary fix for https://github.com/vueuse/vueuse/pull/3847
        // TODO: remove this when the issue is fixed
        scrollLock.value = true
        scrollLock.value = false
        // -----------------------------------
        bodyElement.value!.style.paddingRight = ''
        return scrollLock.value
    }

    return {
        _lock,
        _unlock,
    }
}
