import type { ApiModel } from '../api.model'
import type { ConstructorType } from '../../types/utils'
import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack'
import { type ApiRoutePrefixes } from '../../types/module'
import { ApiUrlBuilder } from '../url-builder/api.url-builder'
import { joinURL } from 'ufo'
import { useConfig } from '../../private/config'
import { useStateHeaders } from '../../private/state'
import { ApiResponse, type ApiResponseMeta } from '../api.response'
import { ApiResponseError } from '../api.response-error'
import type { FetchOptions, FetchResponse } from 'ofetch'
import { appendResponseHeader, H3Event, parseCookies } from 'h3'
import { useRequestEvent } from 'nuxt/app'
import type { MaybePromise } from 'rollup'
import { isFetchingFromSubdomain } from '../../composables/url'

export type ApiServiceFetchOptions<T extends ApiModel> = Partial<ServiceFetchOptions<T>>

type ServiceFetchOptions<T extends ApiModel> = {
    /**
     * A function to call after the response is received.
     * @param response The response of the API call.
     */
    onResponse: (response: ApiResponse<T>) => MaybePromise<void>
}

export type ServiceFetchBaseOptions<R extends NitroFetchRequest> = {
    method: NitroFetchOptions<R>['method']
    /**
     * The request body.
     */
    body: NitroFetchOptions<R>['body']
}

type ApiServiceOptions = ({
    url: string
    endpoint?: never
} | {
    url?: never
    endpoint: string
}) & {
    routePrefix?: keyof ApiRoutePrefixes
}

export function getOnRequestInterceptor(event: H3Event | undefined, isFetchingFromSubdomain: boolean, authTokenCookieName: string, headers: Record<string, string>): NonNullable<FetchOptions['onRequest']> {
    return ({
        options: _options,
        request,
    }) => {
        _options.headers = _options.headers || {}

        _options.headers = {
            // TODO: add client IP & User-Agent headers
            ..._options.headers,
            ...headers,
        }

        // Authentication ------------------------------------------------------------------------------------------
        if (import.meta.server) {
            if (!event) throw new Error('[useFetch] No request event found while fetching on the server.')
            const cookies = parseCookies(event)

            cookies[authTokenCookieName] = event.context.newAuthToken ?? cookies[authTokenCookieName] as string
            _options.headers['cookie' as keyof typeof _options.headers] =
                Object.entries(cookies).map(([key, value]) => `${key}=${value}`).join(';')
        } else if (!isFetchingFromSubdomain) {

            const authToken = import.meta.client && document.cookie.split(';')
                .find(row => row.trim().startsWith(`${authTokenCookieName}=`))
                ?.split('=')[1]

            if (authToken) {
                _options.headers['Authorization' as keyof typeof _options.headers] = `Bearer ${authToken}`
            }
        }
        // ---------------------------------------------------------------------------------------------------------

    }
}

export async function refreshSession(event: H3Event | undefined, isFetchingFromSubdomain: boolean, authTokenCookieName: string) {
    // TODO: make session refresh configurable
    try {
        let cookies: string[] = []

        const response = await $fetch('/api/auth/refresh', {
            method: 'POST',
            credentials: isFetchingFromSubdomain ? 'include' : undefined,
            onResponse: ({ response }) => {
                if (import.meta.server) {
                    cookies = (response.headers.get('set-cookie') || '').split(',')
                }
            },
            onRequest: getOnRequestInterceptor(
                event,
                isFetchingFromSubdomain,
                authTokenCookieName,
                {}
            ),
        })

        // TODO: update the event context `newAuthToken` attribute

        // forward cookies to the client
        if (import.meta.server && event) {
            for (const cookie of cookies) {
                appendResponseHeader(event, 'set-cookie', cookie)
            }
        }


    } catch (e) {
        console.error('[composable api] Error refreshing session', e)
    }
}

export function transformResponse<T extends ApiModel>(model: ConstructorType<T> | null, data: any, responseMeta: Partial<ApiResponseMeta> | null = null) {
    return new ApiResponse(data, model, responseMeta)
}

export function transformError(response: FetchResponse<any>) {
    return new ApiResponseError(response)
}

export async function apiFetch<T extends ApiModel>(options: {
    model: ConstructorType<T> | null
    url: string
    options: (Partial<ApiServiceFetchOptions<T> & ServiceFetchBaseOptions<any>>) | undefined
    event: H3Event | undefined
    isFetchingFromSubdomain: boolean
    authTokenCookieName: string
    headers: Record<string, string>
    params: Record<string, any>
}) {
    let responseMeta: ApiResponseMeta | null = null

    let shouldRetry = false

    const executeFetch = (skipRetry: boolean = false) => $fetch(options.url, {
        method: options.options?.method,
        body: options.options?.body,
        params: options.params,
        credentials: options.isFetchingFromSubdomain ? 'include' : undefined,
        onRequest: getOnRequestInterceptor(
            options.event,
            options.isFetchingFromSubdomain,
            options.authTokenCookieName,
            options.headers
        ),
        onResponse: ({ response }) => {
            responseMeta = {
                status: response.status,
                headers: response.headers,
                body: response.body,
            }
        },
        onResponseError: (context) => {
            // retry only if the response is 401, and we haven't retried yet
            shouldRetry = context.response.status === 401 && !skipRetry
            // throw an error if the request should not be retried
            if (!shouldRetry) throw transformError(context.response)
        },
    })

    let response

    try {
        response = await executeFetch()
    } catch(e) {
        // throw an error if the request should not be retired
        if (!shouldRetry) throw e
        // --------------------------

        // refresh the session
        await refreshSession(
            options.event,
            options.isFetchingFromSubdomain,
            options.authTokenCookieName
        )

        // retry the request
        response = await executeFetch(true)
    }

    return transformResponse(options.model, response, responseMeta)
}

export class ApiService<T extends ApiModel> extends ApiUrlBuilder<T> {
    private readonly model: ConstructorType<T> | null = null

    constructor(options: ApiServiceOptions, model: ConstructorType<T> | null) {

        // capture the values into separate variables because `useConfig()` cannot be passed into
        // the `super()` call directly, because it is a composable and that would make the api url
        // builder not usable in places where the Nuxt instance is not available
        const config = useConfig()
        const apiUrl = config.apiUrl ?? ''
        const apiRoutePrefix = options.routePrefix ?? config.apiRoutePrefix ?? ''


        super({
            url: options.url ?? joinURL(
                apiUrl,
                apiRoutePrefix,
                options.endpoint ?? ''
            ),
        })

        this.model = model
    }

    // -----------------------------------------------------------------------------------------------------------------

    protected async fetch(options?: Partial<ApiServiceFetchOptions<T> & ServiceFetchBaseOptions<any>>) {
        const builderData = this.captureBuilderDataAndReset()
        const builtUrl = builderData.getBuiltUrl()
        const builtParams = builderData.getBuiltParams()

        const _isFetchingFromSubdomain = isFetchingFromSubdomain(builtUrl)
        const authTokenCookieName = useConfig().authTokenCookieName

        const headers = useStateHeaders()

        const response = await apiFetch({
            model: this.model,
            url: builtUrl,
            options: options,
            event: useRequestEvent(),
            isFetchingFromSubdomain: _isFetchingFromSubdomain,
            authTokenCookieName: authTokenCookieName,
            headers: headers.value as Record<string, string>,
            params: builtParams,
        })

        return response
    }

}


