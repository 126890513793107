import { ApiService, type ApiServiceFetchOptions } from '@composable-api/service/api.service'
import { MeModel } from '../models/me.model'

class MeApiService<M extends MeModel> extends ApiService<M> {
    get(options?: ApiServiceFetchOptions<M>) {
        return this
            .embed([
                MeModel.EMBED_CUSTOMER_LATEST_CART_ID,
            ])
            .fetch({
                ...options,
                method: 'GET',
            })
    }
}

export function getMeApiService() {
    return new MeApiService({
        endpoint: '/auth/me',
    }, MeModel)
}
