import type { MaybeRefOrGetter, ModelRef, Ref } from 'vue'
import type { FormErrors, FormEvent, InternalFormElementValue } from '@core-types/components/CoreUIForm'
import type { ZodRawShape } from 'zod'
import { z, ZodObject } from 'zod'
import type { UseEventBusReturn } from '@vueuse/core'
import type Swiper from 'swiper'
import type { TabSwitcherOption } from '@core-types/components/CoreUITabSwitcher'
import type { MakeKeysOptional } from '@core-types/utility'
import type { MaybePromise } from 'rollup'

// Component Provides

export const SymbolCoreUiForm = Symbol('CoreUiForm')
export type CoreUiFormProvide<T extends ZodRawShape, E> = {
    formErrors: Ref<FormErrors<T, E>>
    resetFormError: (field: keyof z.infer<ZodObject<T>>) => void
    registerCallback: (event: 'before-submit', callback: () => MaybePromise<boolean | void>) => void
    bus: UseEventBusReturn<FormEvent, any>
}

/**
 * Inject the attributes provided by <CoreUiForm />
 */
export function useCoreUiFormProvide<T extends ZodRawShape, E>() {
    const injected =  inject<Partial<CoreUiFormProvide<T, E>>>(SymbolCoreUiForm, {})

    return { injected }
}


// -----------

export type CheckboxPrimitiveValue = boolean | string | number | null
export type CheckboxValue = CheckboxPrimitiveValue | CheckboxPrimitiveValue[]

export const SymbolCoreUiFormRadioGroup = Symbol('CoreUiFormRadioGroup')
export type CoreUiFormRadioGroupProvide = {
    name: Ref<string>
    inputValue:  ModelRef<CheckboxValue | undefined>
    formInputValue: ModelRef<InternalFormElementValue<CheckboxValue> | undefined>
    isRadioChecked: (value: CheckboxValue) => boolean
}

/**
 * Inject the attributes provided by <CoreProvideRadioGroup />
 */
export function useCoreProvideRadioGroupProvide() {
    const injected =  inject<CoreUiFormRadioGroupProvide>(SymbolCoreUiFormRadioGroup)

    if (!injected && import.meta.dev) {
        console.warn('A radio component should always be wrapped in a <CoreProvideRadioGroup /> component.')
    }

    return { injected }
}

export function useRadioInGroup() {
    const { injected } = useCoreProvideRadioGroupProvide()
    return {
        isRadioChecked: injected?.isRadioChecked ?? (() => {
            errorLog('[useRadiInGroup]: The component is not wrapped in a <CoreProvideRadioGroup /> component.')
            return false
        }),
    }
}

// -----------

export const SymbolBaseModal = Symbol('BaseModal')
export type BaseModalProvide = {
    close: () => void
}

/**
 * Inject the attributes provided by <BaseModal />
 */
export function useBaseModalProvide() {
    const injected =  inject<BaseModalProvide>(SymbolBaseModal)

    if (!injected && import.meta.dev) {
        console.warn('A modal building block component should always be wrapped in a <BaseModal /> component.')
    }

    return { injected }
}

// -----------

export const SymbolCoreSwiperWrapper = Symbol('CoreSwiperWrapper')
export type CoreSwiperWrapperProvide = {
    swiper: Ref<Swiper | null>
    swiperParent: Ref<Swiper | null | undefined>
    numberOfSlides: Ref<number>
    activeSlideIndex: Ref<number>
    setSwiper: (swiper: Swiper) => void
    setNumberOfSlides: (numberOfSlides: number) => void
    setActiveSlideIndex: (index: number) => void
    callbackSetParent: (callback: (parent: Swiper) => void) => void
    callbackInitializeThumbs: (callback: (swiper: Swiper, parent: Swiper) => void) => void
}

/**
 * Inject the attributes provided by <CoreSwiperWrapper />
 */
export function useCoreSwiperWrapperProvide() {
    const injected =  inject<CoreSwiperWrapperProvide | undefined>(SymbolCoreSwiperWrapper, undefined)

    return { injected }
}

// =====================================================================================================================

type _TabSwitcherOption = MakeKeysOptional<TabSwitcherOption, 'id'>

interface UseTabSwitcherOptions {
    autoSelectFirstOption?: boolean
}

export function useTabSwitcher(values: MaybeRefOrGetter<_TabSwitcherOption[]>, options?: Partial<UseTabSwitcherOptions>) {

    const _values = computed<TabSwitcherOption[]>(() => {
        return toValue(values).map(option => {
            return {
                id: option.id ?? useId(),
                ...option,
            }
        })
    })

    const selectedOption = ref<TabSwitcherOption | null>(options?.autoSelectFirstOption ? _values.value[0] ?? null : null)

    return {
        options: _values,
        selectedOption: selectedOption,
    }
}
