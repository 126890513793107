import { ApiLanguageCodesMap, type LangID, type Monetary, type Translation } from '../types/general-data'

/**
 * Transforms the language code used in HTML and for the selected i18n locale (ISO 639-1; e.g. `en`)
 * to the language code used in the API (ISO 639-2/B; e.g. `eng`).
 *
 * @returns The language code used in the API or `null` if the language code is not supported.
 * @todo remove when BE switches over to ISO-1
 */
export function getLocaleKey(code: LangID | undefined = undefined) {
    // @ts-ignore
    return ApiLanguageCodesMap[code as keyof typeof ApiLanguageCodesMap ?? useI18n().locale.value as keyof typeof ApiLanguageCodesMap] ?? null
}

/**
 * Gets the localized value from the translation object based on the current locale.
 * @param translation The translation object to get the localized value from.
 * @returns value The localized value or `null` if the value does not exist.
 */
export function getLocalizedValue(translation: Translation | undefined | null) {
    if (typeof translation !== 'object') return translation
    return translation?.[getLocaleKey()] ?? null
}

/**
 * Gets the localized value from the translation object but rather than returning `null` if the value does not exist,
 * it returns an empty string.
 * Otherwise, it behaves the same as `getLocalizedValue`.
 * @see getLocalizedValue
 * @param translation The translation object to get the localized value from.
 * @returns value The localized value or an empty string if the value does not exist.
 */
export function getSafeLocalizedValue(translation: Parameters<typeof getLocalizedValue>[0]) {
    return getLocalizedValue(translation) ?? ''
}

/**
 * Gets the localized value from the Translation object.
 * A difference between this and `getLocalizedValue` is that this function returns `undefined` if the translation
 * does not exist.
 * This is useful not to add an attribute to an HTML element when the translation does not exist.
 * @see getLocalizedValue
 * @param translation The translation object to get the localized value from.
 * @returns The localized value or `undefined` if the translation does not exist.
 */
export function getStrictLocalizedValue(translation: Parameters<typeof getLocalizedValue>[0]) {
    return getLocalizedValue(translation) ?? undefined
}

/**
 * Get the formatted price from the monetary object.
 * Can return `null` if the monetary object is not suitable. (e.g. not an object but a number and so on)
 *
 * Only this function should be used to get the formatted price.
 * @param monetary
 */
export function getFormattedPrice(monetary: Monetary | null | undefined): string | null {
    if (monetary === null || monetary === undefined) return null
    // if monetary is a number, there is no formatted price
    if (typeof monetary === 'number') return null

    // if we're sending the currently selected currency, monetary only represents the current currency
    if ('formatted' in monetary && typeof monetary.formatted === 'string') return monetary.formatted

    // monetary is an object with every currency, return the currently selected currency
    // TODO
    return null
}

/**
 * Get the numeric value from the monetary object.
 * Only this function should be used to get the price value.
 * @param monetary
 */
export function getPriceValue(monetary: Monetary | null | undefined): number | null {
    if (monetary === null || monetary === undefined) return null

    // if monetary is a number, return it
    if (typeof monetary === 'number') return monetary

    // if we're sending the currently selected currency, monetary only represents the current currency
    if ('value' in monetary && typeof monetary.value === 'number') return monetary.value

    // monetary is an object with every currency, return the currently selected currency
    // TODO
    return null
}
