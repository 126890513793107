import type { Events } from '@core-types/events'
import type { MaybePromise } from 'rollup'

type EventCallback<T> = (data: T) => MaybePromise<void>

interface OnEventOptions {
    /**
     * Whether to run the callback only once and
     * then automatically unregister the listener.
     * @default false
     */
    once?: boolean
}

export const useEventsStore = defineStore('events', () => {
    const eventCallbacks: Partial<{
        [K in keyof Events]: Set<EventCallback<Events[K]>>
    }> = {}

    /**
     * Listen to an event and call the callback when the event happens.
     *
     * This function doesn't unregister the listener automatically,
     * so make sure to call the returned function at some point to remove the listener.
     *
     * @param event The name of the event to listen to
     * @param callback The callback to call when the event happens
     * @param options Options for the listener
     * @returns A function to remove the listener
     */
    function on<K extends keyof Events>(event: K, callback: EventCallback<Events[K]>, options: OnEventOptions = {}) {
        const callbacks = eventCallbacks[event] || new Set<EventCallback<Events[K]>>()

        const unregister = () => {
            const callbacks = eventCallbacks[event]
            if (!callbacks) return
            callbacks.delete(callback)
            if (callbacks.size === 0) {
                delete eventCallbacks[event]
            }
        }

        // add the callback to the list
        callbacks.add(
            options.once
                ? (data: Events[K]) => {
                    unregister()
                    callback(data)
                }
                : callback
        )

        // @ts-ignore
        eventCallbacks[event] = callbacks

        // return a function to remove the listener
        return unregister
    }

    /**
     * This is a wrapper function around `on` that automatically unregisters the
     * listener when the component it was used in is unmounted.
     *
     * This only works at the top of the script setup block in a component
     * and will throw an error if used elsewhere.
     *
     * @param event The name of the event to listen to
     * @param callback The callback to call when the event happens
     * @param options Options for the listener
     * @throws Error if used outside the script setup block of a component
     */
    function useOn<K extends keyof Events>(event: K, callback: EventCallback<Events[K]>, options: OnEventOptions = {}) {
        if (!getCurrentInstance()) {
            throw new Error('`useOn()` can only be used at the top of the script setup block of a component')
        }

        const unregister = on(event, callback, options)
        onUnmounted(unregister)
    }

    /**
     * Emit an event with its data.
     * @param event The name of the event to emit
     * @param data The data to pass to the event listeners
     */
    async function emit<K extends keyof Events>(event: K, data: Events[K]) {
        const callbacks = eventCallbacks[event]
        if (!callbacks) return
        try {
            await Promise.all(Array.from(callbacks).map(cb => cb(data)))
        } catch (err) {
            errorLog(`Error while emitting event '${event}'`, err)
        }
    }

    return {
        on,
        useOn,
        emit,
    }
})
