import { default as _91_46_46_46slug_93yri8I4l3CyMeta } from "/opt/simploshop/shared/core-theme/app/pages/[...slug].vue?macro=true";
import { default as deliverykb9FWjvScIMeta } from "/opt/simploshop/themes/theme/app/pages/cart/delivery.vue?macro=true";
import { default as indexwXFJIq9Zl1Meta } from "/opt/simploshop/themes/theme/app/pages/cart/index.vue?macro=true";
import { default as payment_45summaryJdTQGnCC8JMeta } from "/opt/simploshop/themes/theme/app/pages/cart/payment-summary.vue?macro=true";
import { default as shipping_45and_45paymentt1NWXoYm1cMeta } from "/opt/simploshop/themes/theme/app/pages/cart/shipping-and-payment.vue?macro=true";
import { default as summaryd1Jh7BHc5tMeta } from "/opt/simploshop/themes/theme/app/pages/cart/summary.vue?macro=true";
import { default as cart7Tm5017WO8Meta } from "/opt/simploshop/themes/theme/app/pages/cart.vue?macro=true";
import { default as accountT3vDm0NuhFMeta } from "/opt/simploshop/themes/theme/app/pages/customer/account.vue?macro=true";
import { default as dashboardfITFgFXSOxMeta } from "/opt/simploshop/themes/theme/app/pages/customer/dashboard.vue?macro=true";
import { default as favourite_45productsyc5iNVCUTxMeta } from "/opt/simploshop/themes/theme/app/pages/customer/favourite-products.vue?macro=true";
import { default as my_45addressesGo4gcpeozjMeta } from "/opt/simploshop/themes/theme/app/pages/customer/my-addresses.vue?macro=true";
import { default as _91id_932b5VXwE0yYMeta } from "/opt/simploshop/themes/theme/app/pages/customer/my-orders/[id].vue?macro=true";
import { default as indexte9R8qR5TRMeta } from "/opt/simploshop/themes/theme/app/pages/customer/my-orders/index.vue?macro=true";
import { default as my_45ordersZu375N6vszMeta } from "/opt/simploshop/themes/theme/app/pages/customer/my-orders.vue?macro=true";
import { default as customercnDTmB04WxMeta } from "/opt/simploshop/themes/theme/app/pages/customer.vue?macro=true";
import { default as indexH32omecVk8Meta } from "/opt/simploshop/themes/theme/app/pages/index.vue?macro=true";
import { default as loginBoQPkTQHjNMeta } from "/opt/simploshop/themes/theme/app/pages/login.vue?macro=true";
import { default as password_45resetLUKycvCUiPMeta } from "/opt/simploshop/themes/theme/app/pages/password-reset.vue?macro=true";
export default [
  {
    name: "slug___cs",
    path: "/:slug(.*)*",
    component: () => import("/opt/simploshop/shared/core-theme/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___sk",
    path: "/:slug(.*)*",
    component: () => import("/opt/simploshop/shared/core-theme/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/opt/simploshop/shared/core-theme/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: cart7Tm5017WO8Meta?.name,
    path: "/cart",
    meta: cart7Tm5017WO8Meta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/cart.vue").then(m => m.default || m),
    children: [
  {
    name: "cart-delivery___cs",
    path: "/kosik/doruceni",
    component: () => import("/opt/simploshop/themes/theme/app/pages/cart/delivery.vue").then(m => m.default || m)
  },
  {
    name: "cart___cs",
    path: "/kosik",
    component: () => import("/opt/simploshop/themes/theme/app/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "cart-payment-summary___cs",
    path: "/kosik/shrnuti-platby",
    meta: payment_45summaryJdTQGnCC8JMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/cart/payment-summary.vue").then(m => m.default || m)
  },
  {
    name: "cart-shipping-and-payment___cs",
    path: "/kosik/doprava-a-platba",
    component: () => import("/opt/simploshop/themes/theme/app/pages/cart/shipping-and-payment.vue").then(m => m.default || m)
  },
  {
    name: "cart-summary___cs",
    path: "/kosik/shrnuti",
    meta: summaryd1Jh7BHc5tMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/cart/summary.vue").then(m => m.default || m)
  }
]
  },
  {
    name: cart7Tm5017WO8Meta?.name,
    path: "/cart",
    meta: cart7Tm5017WO8Meta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/cart.vue").then(m => m.default || m),
    children: [
  {
    name: "cart-delivery___sk",
    path: "/kosik/dorucenie",
    component: () => import("/opt/simploshop/themes/theme/app/pages/cart/delivery.vue").then(m => m.default || m)
  },
  {
    name: "cart___sk",
    path: "/kosik",
    component: () => import("/opt/simploshop/themes/theme/app/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "cart-payment-summary___sk",
    path: "/kosik/zhrnutie-platby",
    meta: payment_45summaryJdTQGnCC8JMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/cart/payment-summary.vue").then(m => m.default || m)
  },
  {
    name: "cart-shipping-and-payment___sk",
    path: "/kosik/doprava-a-platba",
    component: () => import("/opt/simploshop/themes/theme/app/pages/cart/shipping-and-payment.vue").then(m => m.default || m)
  },
  {
    name: "cart-summary___sk",
    path: "/kosik/zhrnutie",
    meta: summaryd1Jh7BHc5tMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/cart/summary.vue").then(m => m.default || m)
  }
]
  },
  {
    name: cart7Tm5017WO8Meta?.name,
    path: "/cart",
    meta: cart7Tm5017WO8Meta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/cart.vue").then(m => m.default || m),
    children: [
  {
    name: "cart-delivery___en",
    path: "delivery",
    component: () => import("/opt/simploshop/themes/theme/app/pages/cart/delivery.vue").then(m => m.default || m)
  },
  {
    name: "cart___en",
    path: "/my-cart",
    component: () => import("/opt/simploshop/themes/theme/app/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "cart-payment-summary___en",
    path: "payment-summary",
    meta: payment_45summaryJdTQGnCC8JMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/cart/payment-summary.vue").then(m => m.default || m)
  },
  {
    name: "cart-shipping-and-payment___en",
    path: "shipping-and-payment",
    component: () => import("/opt/simploshop/themes/theme/app/pages/cart/shipping-and-payment.vue").then(m => m.default || m)
  },
  {
    name: "cart-summary___en",
    path: "summary",
    meta: summaryd1Jh7BHc5tMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/cart/summary.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "customer___cs",
    path: "/customer",
    meta: customercnDTmB04WxMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/customer.vue").then(m => m.default || m),
    children: [
  {
    name: "customer-account___cs",
    path: "/zakaznik/informace-o-uctu",
    meta: accountT3vDm0NuhFMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/customer/account.vue").then(m => m.default || m)
  },
  {
    name: "customer-dashboard___cs",
    path: "/zakaznik/dashboard",
    meta: dashboardfITFgFXSOxMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/customer/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "customer-favourite-products___cs",
    path: "/zakaznik/oblibene-produkty",
    meta: favourite_45productsyc5iNVCUTxMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/customer/favourite-products.vue").then(m => m.default || m)
  },
  {
    name: "customer-my-addresses___cs",
    path: "/zakaznik/adresar",
    meta: my_45addressesGo4gcpeozjMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/customer/my-addresses.vue").then(m => m.default || m)
  },
  {
    name: my_45ordersZu375N6vszMeta?.name,
    path: "/zakaznik/moje-objednavky",
    meta: my_45ordersZu375N6vszMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/customer/my-orders.vue").then(m => m.default || m),
    children: [
  {
    name: "customer-my-orders-id___cs",
    path: "/zakaznik/objednavka/:id()",
    meta: _91id_932b5VXwE0yYMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/customer/my-orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "customer-my-orders___cs",
    path: "",
    component: () => import("/opt/simploshop/themes/theme/app/pages/customer/my-orders/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "customer___sk",
    path: "/customer",
    meta: customercnDTmB04WxMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/customer.vue").then(m => m.default || m),
    children: [
  {
    name: "customer-account___sk",
    path: "/zakaznik/informacie-o-ucte",
    meta: accountT3vDm0NuhFMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/customer/account.vue").then(m => m.default || m)
  },
  {
    name: "customer-dashboard___sk",
    path: "/zakaznik/dashboard",
    meta: dashboardfITFgFXSOxMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/customer/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "customer-favourite-products___sk",
    path: "/zakaznik/oblubene-produkty",
    meta: favourite_45productsyc5iNVCUTxMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/customer/favourite-products.vue").then(m => m.default || m)
  },
  {
    name: "customer-my-addresses___sk",
    path: "/zakaznik/adresar",
    meta: my_45addressesGo4gcpeozjMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/customer/my-addresses.vue").then(m => m.default || m)
  },
  {
    name: my_45ordersZu375N6vszMeta?.name,
    path: "/zakaznik/moje-objednavky",
    meta: my_45ordersZu375N6vszMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/customer/my-orders.vue").then(m => m.default || m),
    children: [
  {
    name: "customer-my-orders-id___sk",
    path: "/zakaznik/objednavka/:id()",
    meta: _91id_932b5VXwE0yYMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/customer/my-orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "customer-my-orders___sk",
    path: "",
    component: () => import("/opt/simploshop/themes/theme/app/pages/customer/my-orders/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "customer___en",
    path: "/customer",
    meta: customercnDTmB04WxMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/customer.vue").then(m => m.default || m),
    children: [
  {
    name: "customer-account___en",
    path: "account",
    meta: accountT3vDm0NuhFMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/customer/account.vue").then(m => m.default || m)
  },
  {
    name: "customer-dashboard___en",
    path: "dashboard",
    meta: dashboardfITFgFXSOxMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/customer/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "customer-favourite-products___en",
    path: "favourite-products",
    meta: favourite_45productsyc5iNVCUTxMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/customer/favourite-products.vue").then(m => m.default || m)
  },
  {
    name: "customer-my-addresses___en",
    path: "my-addresses",
    meta: my_45addressesGo4gcpeozjMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/customer/my-addresses.vue").then(m => m.default || m)
  },
  {
    name: my_45ordersZu375N6vszMeta?.name,
    path: "my-orders",
    meta: my_45ordersZu375N6vszMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/customer/my-orders.vue").then(m => m.default || m),
    children: [
  {
    name: "customer-my-orders-id___en",
    path: "/customer/order/:id()",
    meta: _91id_932b5VXwE0yYMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/customer/my-orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "customer-my-orders___en",
    path: "",
    component: () => import("/opt/simploshop/themes/theme/app/pages/customer/my-orders/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "index___cs",
    path: "/",
    meta: indexH32omecVk8Meta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___sk",
    path: "/",
    meta: indexH32omecVk8Meta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    meta: indexH32omecVk8Meta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login___cs",
    path: "/prihlaseni",
    meta: loginBoQPkTQHjNMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___sk",
    path: "/prihlasenie",
    meta: loginBoQPkTQHjNMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/login",
    meta: loginBoQPkTQHjNMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "password-reset___cs",
    path: "/obnova-hesla",
    meta: password_45resetLUKycvCUiPMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: "password-reset___sk",
    path: "/obnova-hesla",
    meta: password_45resetLUKycvCUiPMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: "password-reset___en",
    path: "/password-reset",
    meta: password_45resetLUKycvCUiPMeta || {},
    component: () => import("/opt/simploshop/themes/theme/app/pages/password-reset.vue").then(m => m.default || m)
  }
]