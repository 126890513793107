<script lang="tsx">
import type { ComponentInstance, FunctionalComponent, SlotsType } from 'vue'
import type { NuxtLinkProps } from '#app'
import type { MaybePromise } from 'rollup'
import type { ButtonVariants, ComponentOverrideOptions, SizeProp } from '../../../../types/components'
import { NuxtLink, CoreIconLoadingSpinner } from '#components'

export type CoreUiButtonProps<Colors extends string, Variants extends string, Sizes extends string> = {
    contentClass?: string

    type?: 'button' | 'submit' | 'reset'

    color?: Colors
    variant?: Variants
    size?: Sizes
    align?: 'left' | 'center' | 'right' | 'between'

    dense?: boolean
    square?: boolean
    pill?: boolean

    loading?: boolean
    disabled?: boolean | 'silently'

    onClick?: () => MaybePromise<any>

    to?: NuxtLinkProps['to'] | null
    target?: NuxtLinkProps['target']
    ariaLabel?: string
    ariaDescribedBy?: string
}

type CoreUiButtonSlots<Colors extends string, Variants extends string, Sizes extends string> = {
    default: {}
    leading: {}
    trailing: {}
    leadingSprite: {
        loaderClass: string
    }
}

type ComponentOptions = {}

export function defineComponentCoreUiButton<
    Colors extends string,
    Variants extends string = ButtonVariants,
    Sizes extends string = SizeProp,
>(options?: ComponentOverrideOptions<ComponentOptions, CoreUiButtonProps<Colors, Variants, Sizes>, CoreUiButtonSlots<Colors, Variants, Sizes>>) {
    return defineComponent(
        (props: CoreUiButtonProps<Colors, Variants, Sizes>, ctx) => {

            const tag = computed(() => props.to ? MutableNuxtLink as ComponentInstance<any> : 'button')
            const isLink = computed(() => props.to !== undefined)

            const internalLoading = ref<boolean>(false)
            const isLoading = computed(() => props.loading || internalLoading.value)
            const isDisabled = computed(() => props.disabled || isLoading.value)

            const parentScope = getParentScope()

            async function handleButtonClick() {
                if (isDisabled.value) return

                if (props.onClick) {
                    const maybePromise = props.onClick()
                    if (maybePromise instanceof Promise) {
                        internalLoading.value = true

                        try {
                            await maybePromise
                        } finally {
                            internalLoading.value = false
                        }
                    }
                }
            }

            // console.log(ctx.slots.leading !== undefined)

            return () => (
                <tag.value {...{
                    'class': ['sim-btn', {
                        [`sim-btn--c-${props.color}`]: props.color,
                        [`sim-btn--s-${props.size}`]: props.size,
                        [`sim-btn--v-${props.variant}`]: props.variant,
                        'sim-btn--pill': props.pill,
                        'sim-btn--dense': props.dense,
                        'sim-btn--square': props.square,
                        'sim-btn--loading': isLoading.value,
                        'sim-btn--disabled': isDisabled.value && props.disabled !== 'silently',
                        'sim-btn--disabled-silent': isDisabled.value && props.disabled === 'silently',
                    }],
                    'disabled': isDisabled.value,
                    ...(isLink.value ? {
                        to: props.to,
                        target: props.target,
                    } : {
                        type: props.type,
                        onClick: handleButtonClick,
                    }),
                    'aria-label': props.ariaLabel,
                    'aria-describedby': props.ariaDescribedBy,
                }}>
                    <span {...{
                        style: isLoading.value ? 'visibility: hidden' : undefined,
                        class: ['sim-btn__content', {
                            'justify-start': props.align === 'left',
                            'justify-center': props.align === 'center',
                            'justify-end': props.align === 'right',
                            'justify-between': props.align === 'between',
                            [`${props.contentClass}`]: props.contentClass,
                        }],
                        ...parentScope,
                    }}>
                        {
                            // LEADING SLOT
                            (ctx.slots.leading !== undefined || options?.slots?.leading) && (
                                <span class="sim-btn__leading" aria-hidden="true">
                                    { renderSlot(ctx.slots.leading, options?.slots?.leading, {} as never) }
                                </span>
                            )
                        }

                        {
                            // DEFAULT SLOT
                            renderSlot(ctx.slots.default, options?.slots?.default, {})
                        }

                        {
                            // TRAILING SLOT
                            (ctx.slots.trailing !== undefined || options?.slots?.trailing) && (
                                <span class="sim-btn__trailing" aria-hidden="true">
                                    { renderSlot(ctx.slots.trailing, options?.slots?.trailing, {} as never) }
                                </span>
                            )
                        }
                    </span>

                    {
                        isLoading.value && renderSlot(ctx.slots.leadingSprite, options?.slots?.leadingSprite, {
                            loaderClass: 'loaderClass',
                        }, (
                            <CoreIconLoadingSpinner class="sim-btn__loader" />
                        ))
                    }
                </tag.value>
            )
        },
        {
            props: {
                contentClass: {
                    type: String as PropType<CoreUiButtonProps<Colors, Variants, Sizes>['contentClass']>,
                    default: options?.props?.contentClass?.default,
                    required: options?.props?.contentClass?.required ?? false,
                },
                type: {
                    type: String as PropType<CoreUiButtonProps<Colors, Variants, Sizes>['type']>,
                    default: options?.props?.type?.default ?? 'button',
                    required: options?.props?.type?.required ?? false,
                },

                color: {
                    // @ts-ignore
                    type: String as PropType<CoreUiButtonProps<Colors, Variants, Sizes>['color']>,
                    default: options?.props?.color?.default,
                    required: options?.props?.color?.required ?? false,
                },
                variant: {
                    // @ts-ignore
                    type: String as PropType<CoreUiButtonProps<Colors, Variants, Sizes>['variant']>,
                    default: options?.props?.variant?.default,
                    required: options?.props?.variant?.required ?? false,
                },
                size: {
                    // @ts-ignore
                    type: String as PropType<CoreUiButtonProps<Colors, Variants, Sizes>['size']>,
                    default: options?.props?.size?.default,
                    required: options?.props?.size?.required ?? false,
                },
                align: {
                    type: String as PropType<CoreUiButtonProps<Colors, Variants, Sizes>['align']>,
                    default: options?.props?.align?.default ?? 'center',
                    required: options?.props?.align?.required ?? false,
                },

                dense: {
                    type: Boolean as PropType<CoreUiButtonProps<Colors, Variants, Sizes>['dense']>,
                    default: options?.props?.dense?.default ?? false,
                    required: options?.props?.dense?.required ?? false,
                },
                square: {
                    type: Boolean as PropType<CoreUiButtonProps<Colors, Variants, Sizes>['square']>,
                    default: options?.props?.square?.default ?? false,
                    required: options?.props?.square?.required ?? false,
                },
                pill: {
                    type: Boolean as PropType<CoreUiButtonProps<Colors, Variants, Sizes>['pill']>,
                    default: options?.props?.pill?.default ?? false,
                    required: options?.props?.pill?.required ?? false,
                },

                loading: {
                    type: Boolean as PropType<CoreUiButtonProps<Colors, Variants, Sizes>['loading']>,
                    default: options?.props?.loading?.default ?? false,
                    required: options?.props?.loading?.required ?? false,
                },
                disabled: {
                    type: [Boolean, String] as PropType<CoreUiButtonProps<Colors, Variants, Sizes>['disabled']>,
                    default: options?.props?.disabled?.default ?? false,
                    required: options?.props?.disabled?.required ?? false,
                },

                onClick: {
                    type: Function as PropType<CoreUiButtonProps<Colors, Variants, Sizes>['onClick']>,
                    default: options?.props?.onClick?.default,
                    required: options?.props?.onClick?.required ?? false,
                },

                to: {
                    type: String as PropType<CoreUiButtonProps<Colors, Variants, Sizes>['to']>,
                    default: options?.props?.to?.default,
                    required: options?.props?.to?.required ?? false,
                },
                target: {
                    type: String as PropType<CoreUiButtonProps<Colors, Variants, Sizes>['target']>,
                    default: options?.props?.target?.default,
                    required: options?.props?.target?.required ?? false,
                },
                ariaLabel: {
                    type: String as PropType<CoreUiButtonProps<Colors, Variants, Sizes>['ariaLabel']>,
                    default: options?.props?.ariaLabel?.default,
                    required: options?.props?.ariaLabel?.required ?? false,
                },
                ariaDescribedBy: {
                    type: String as PropType<CoreUiButtonProps<Colors, Variants, Sizes>['ariaDescribedBy']>,
                    default: options?.props?.ariaDescribedBy?.default,
                    required: options?.props?.ariaDescribedBy?.required ?? false,
                },

            },
            slots: Object as SlotsType<CoreUiButtonSlots<Colors, Variants, Sizes>>,
            emits: {},
        }
    )
}

export default defineComponentCoreUiButton()

const MutableNuxtLink: FunctionalComponent<{
    disabled?: boolean,
    to: NuxtLinkProps['to']
    target?: NuxtLinkProps['target']
}> = (props, { slots, emit, attrs }) => {
    if (props.disabled) {
        return h('div', {
            ...attrs,
            class: 'cursor-not-allowed inline-block',
        }, [
            h(NuxtLink, {
                to: props.to ?? '',
                target: props.target,
                class: 'pointer-events-none',
                tabindex: -1,
            }, () => [
                slots.default?.(),
            ]),
        ])
    }

    return h(NuxtLink, {
        to: props.to ?? '',
        target: props.target,
        class: 'inline-block',
    }, () => [
        slots.default?.(),
    ])
}

</script>

<style lang="scss" scoped>
@use "@core-scss/components/CoreUiButton.scss" as *;

</style>
