// TODO: Add to theme layer when dynamic import relative to current project is figured out

export default defineNuxtPlugin({
    name: 'routing',
    parallel: true,
    setup: (nuxtApp) => {
        const router = useRouter()
        const appConfig = useAppConfig()

        // the currently known dynamic routes
        const pages = useStatePage()

        // ROUTER RULE - BEFORE EACH NAVIGATION
        router.beforeEach(async (to) => {
            const isRouteInRouter = router.getRoutes().some(route => route.path === to.path)
            let page = pages.value.get(to.path)


            // if the page is not a backend page, continue to Nuxt routing
            if (page === null) return

            // if we don't have the page data, fetch it
            if (page === undefined) {
                try {
                    if (import.meta.server) {
                        performanceCheckpoint(useRequestEvent()!, 'page-api')
                    }
                    const pageResponse = await getPageApiService().forId(to.path).get()
                    page = pageResponse.getItem()
                    if (import.meta.server) {
                        performanceCheckpoint(useRequestEvent()!, 'page-api')
                    }
                } catch (e) {
                    // this page is not a backend page, store `null` to prevent unnecessary API requests
                    pages.value.set(to.path, null)
                    // continue to Nuxt routing
                    return
                }

                pages.value.set(to.path, page)
            }

            // add the route to the router if it's not already there
            let route = null
            if (!isRouteInRouter) {
                const pageTypeName = page?.getPageType()

                // if the page type is not known, continue to Nuxt routing
                if (!pageTypeName) return   // should go to 404 or something

                route = {
                    path: to.path,
                    name: `dynamic${to.path.replace(/\//g, '-')}`,
                    component: () => import((`../pages-dynamic/${pageTypeName}.vue`)),
                }
                router.addRoute(route)
            }

            return route ? { name: route.name, query: to.query } : undefined
        })
    },
})
