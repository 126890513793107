import type { ApiModel, ApiModelKey } from './api.model'
import type { ConstructorType } from '../types/utils'
import { createModelKey } from '../utils/serialization'

export type ApiResponseReducedData = [ApiResponseData | null, ApiModelKey | null, Partial<ApiResponseMeta> | null]

const RESPONSE_ITEMS_KEY = 'items' as const
const RESPONSE_META_KEY = '_meta' as const

type ApiResponseData = {
    [RESPONSE_ITEMS_KEY]?: any[]
    [RESPONSE_META_KEY]?: {
        page: number
        per_page: number
        records: number
        total_pages: number
    }
} & { [key: string]: any }

export type ApiResponseMeta = {
    status: number
    headers: Headers
    body: unknown
}

export class ApiResponse<T extends ApiModel> {
    private items: T[] | null = null
    private readonly data: ApiResponseData | null   // can be null when 'No Content' response is returned
    protected model: ConstructorType<T> | null
    private readonly responseMeta: Partial<ApiResponseMeta> | null

    constructor(data: ApiResponseData | null | undefined, model: ConstructorType<T> | null, responseMeta: Partial<ApiResponseMeta> | null = null) {
        this.data = data ?? null
        this.model = model
        this.responseMeta = responseMeta
    }

    /**
     * Get the meta information of the resource.
     * For example, the current page, total number of pages, etc.
     * (Not to be confused with the response metadata - which includes status, headers, etc.)
     * @private
     */
    private get meta() {
        return this.data?.[RESPONSE_META_KEY] ?? null
    }

    /**
     * Get the reduced data of the ApiResponse so that it can be constructed again
     * after being serialized.
     */
    getReducedData(): ApiResponseReducedData {
        return [this.data, createModelKey(this.model), this.responseMeta]
    }

    /**
     * Get the items from the response.
     * If the model is provided, the items are instantiated as models.
     */
    getItems() {
        if (this.items) return this.items

        this.items = this.data?.[RESPONSE_ITEMS_KEY]
            ? this.model
                ? this.data[RESPONSE_ITEMS_KEY].map(item => new this.model!(item))
                : this.data[RESPONSE_ITEMS_KEY]
            : []

        return this.items
    }

    /**
     * Get the response resource.
     * If the model is provided, the item is instantiated as a model.
     */
    getItem() {
        if (this.items) return this.items[0] ?? null

        this.items = this.model
            ? [new this.model(this.data)]
            : [this.data as T]

        return this.items[0]!
    }

    // ------------------------------------------------------------------

    /**
     * Check whether it is the last page of the response.
     * Returns True if not paginated.
     */
    isLastPage(): boolean {
        if (!this.meta) return true
        return this.meta.total_pages <= this.meta.page
    }

    /**
     * Get the resource metadata from the API.
     * This includes information about the current page, total number of pages, etc.
     */
    getMeta() {
        return this.meta
    }

    /**
     * Get the status code of the response.
     */
    getStatus() {
        return this.responseMeta?.status ?? null
    }

    /**
     * Check if the response status code is the same as the provided one.
     */
    isStatus(status: number) {
        return this.getStatus() === status
    }

    /**
     * Get the headers of the response.
     */
    getHeaders() {
        return this.responseMeta?.headers ?? null
    }

    /**
     * Get the body of the response.
     * This is the raw response body, not the parsed data.
     * Can be `null` if the request was made on the server and needed to be serialized, for example.
     */
    getBody() {
        return this.responseMeta?.body ?? null
    }

}
