import type { CountryModel } from '@simploshop-models/country.model'
import type { CurrencyModel } from '@simploshop-models/currency.model'
import type { FreeShippingModel } from '@simploshop-models/free-shipping.model'
import type { FormattedCurrency } from '@sim-api-types/general-data'
import type { ShopInfoModel } from '@simploshop-models/shop-info.model'
import type { ComputedRef } from 'vue'
import { getConfigPublicApiUrl } from '@sim-api-utils/url'
import { useComposableApi } from '@composable-api-config'

export const usePropertiesStore = defineStore('properties', () => {

    const countries = ref<CountryModel[] | null>(null)
    const currencies = ref<CurrencyModel[] | null>(null)
    const shopInfo = ref<ShopInfoModel | null>(null)
    const freeShipping = ref<FreeShippingModel | null>(null)

    // TODO - change currentLocaleDomain to not use api url
    const currentLocaleDomain = getConfigPublicApiUrl(useNuxtApp())

    const currentHostname = computed(() => {
        return shopInfo.value?.hostnames?.find(item => item.shop_hostname.hostname === currentLocaleDomain) ?? null
    })

    /**
     * The active currency based on the shop's hostname
     * @todo add support for currency switching?
     */
    const activeCurrency = computed(() => {
        if (!currencies.value || !shopInfo.value) return null

        return currencies.value?.find(
            currency =>
                currency.id === currentHostname.value?.shop_hostname.currency_id
        ) ?? null
    })

    const currencyCode = computed(() => {
        return activeCurrency.value?.id ?? '-'
    })

    const currencySymbol = computed(() => {
        return activeCurrency.value?.symbol ?? '-'
    })

    /**
     * The active country based on the shop's hostname
     */
    const activeCountry = computed(() => {
        if (!countries.value || !shopInfo.value) return null

        return countries.value?.find(
            country =>
                country.id === currentHostname.value?.shop_hostname.country_id
        ) ?? null
    })

    const dialCodes = computed(() => {
        if (!countries.value) return []

        return countries.value.filter(country => country.isActive && !!country.dialCode)
            .filter(country => country.isActive && !!country.dialCode)
            .map(country => country.dialCode)
    })

    const countriesForSelect = computed(() => {
        if (!countries.value) return []

        return countries.value.filter(country => country.isActive)
            .map(country => ({
                label: country.name,
                value: country.id,
            }))
    })

    return {
        countries,
        currencies,
        shopInfo,
        activeCurrency,
        currencyCode,
        currencySymbol,
        activeCountry,
        dialCodes,
        countriesForSelect,
        freeShipping,
    }
})

export const useProperties = () => {
    const store = usePropertiesStore()
    const refs = storeToRefs(store)
    const api = useComposableApi()

    let freeShippingPrice: ComputedRef<FormattedCurrency | null> | undefined
    let lang: ComputedRef<string | null> | undefined

    return {
        currencyModel: refs.activeCurrency,
        currency: refs.currencyCode,
        currencySymbol: refs.currencySymbol,
        countryModel: refs.activeCountry,
        countries: refs.countriesForSelect,
        dialCodes: refs.dialCodes,
        get freeShippingPrice() {
            if (!freeShippingPrice) {
                freeShippingPrice = computed<FormattedCurrency | null>(() => {
                    return store.freeShipping?.getPriceForCurrency(refs.currencyCode.value) ?? null
                })
            }

            return freeShippingPrice
        },
        get lang() {
            if (!lang) {
                lang = computed<string | null>(() => api.getHeader('Language-Preference'))
            }

            return lang
        },
    }
}
