import type {
    ApiDateTime,
    ApiNullableDateTimeRange,
    ComputedPrices,
    Monetary,
    Translation
} from '../types/general-data'
import { ApiModel } from '@composable-api/api.model'
import { ProductVariationModel } from './product-variation.model'
import { ProductAvailabilityModel } from './product-availability.model'
import { AttachmentModel } from './attachment.model'
import { getPriceValue, getFormattedPrice } from '../utils/localization'
import { useT } from '../internal/translation'
import { ProductVariationPropertyModel } from './custom/product-variation-property.model'

interface Attributes {
    [ProductModel.ATTR_ID]: number
    [ProductModel.ATTR_NAME]: string | null
    [ProductModel.ATTR_SHORT_DESCRIPTION]: string | null
    [ProductModel.ATTR_DESCRIPTION]: string | null
    [ProductModel.ATTR_META_TITLE]: string | null
    [ProductModel.ATTR_META_H1_TITLE]: string | null
    [ProductModel.ATTR_META_DESCRIPTION]: string | null
    [ProductModel.ATTR_SLUG]: string | null
    [ProductModel.ATTR_BRAND_ID]: number
    [ProductModel.ATTR_PRODUCT_AVAILABILITY_ID]: number | null
    [ProductModel.ATTR_TAX_GROUP_ID]: number | null
    [ProductModel.ATTR_DEFAULT_CATEGORY_ID]: number | null
    [ProductModel.ATTR_CODE]: string | null
    [ProductModel.ATTR_SKU]: string | null
    [ProductModel.ATTR_EAN]: string | null
    [ProductModel.ATTR_UPC]: string | null
    [ProductModel.ATTR_JAN]: string | null
    [ProductModel.ATTR_ISBN]: string | null
    [ProductModel.ATTR_MPN]: string | null
    [ProductModel.ATTR_UPDATE_STOCK]: boolean
    [ProductModel.ATTR_STOCK_STATE]: number | null
    [ProductModel.ATTR_RESERVED_STATE]: number | null
    [ProductModel.ATTR_POSITION]: number
    [ProductModel.ATTR_HAS_AUTOMATIC_SLUG]: boolean
    [ProductModel.ATTR_IS_ACTIVE]: boolean
    [ProductModel.ATTR_META_INDEX]: string | null
    [ProductModel.ATTR_META_FOLLOW]: string | null
    [ProductModel.ATTR_SHOW_ON_SITEMAP]: boolean
    [ProductModel.ATTR_HEIGHT]: number | null
    [ProductModel.ATTR_WIDTH]: number | null
    [ProductModel.ATTR_DEPTH]: number | null
    [ProductModel.ATTR_WEIGHT]: number | null
    [ProductModel.ATTR_VOLUME]: number | null
    [ProductModel.ATTR_SELLS_COUNT]: number
    [ProductModel.ATTR_PRICE]: Monetary | null
    [ProductModel.ATTR_TAXED_PRICE]: Monetary | null
    [ProductModel.ATTR_TAX_RATE]: Monetary | null
    [ProductModel.ATTR_HAS_VARIATIONS]: boolean
    [ProductModel.ATTR_OG_IMAGE_ID]: number | null
    [ProductModel.ATTR_OG_TITLE]: string | null
    [ProductModel.ATTR_OG_DESCRIPTION]: string | null
    [ProductModel.ATTR_EXPECTED_COUNT]: number | null
    [ProductModel.ATTR_EXPECTED_DATE_MAX]: ApiDateTime | null
    [ProductModel.ATTR_EXPECTED_DATE_MIN]: ApiDateTime | null
    [ProductModel.ATTR_PURCHASABLE_SINCE]: ApiDateTime | null
    [ProductModel.ATTR_MAX_PURCHASABLE_AMOUNT]: number | null
    [ProductModel.ATTR_NEED_PACKAGE]: boolean
    [ProductModel.ATTR_IS_HIDDEN]: boolean
    [ProductModel.ATTR_AMOUNT_UNIT]: string | null
    [ProductModel.ATTR_EXPECTED_DAYS]: number | null
    [ProductModel.ATTR_FEED_AVAILABILITY]: number | null // TODO: should be enum
}

interface Embeds {
    [ProductModel.EMBED_COMPUTED_PRICES]: ComputedPrices
    [ProductModel.EMBED_COMPUTED_TAXED_PRICES]: ComputedPrices
    [ProductModel.EMBED_STOCK_STATES]: ProductStockState[]
    [ProductModel.EMBED_DISCOUNTED_PRICE]: Monetary | null
    [ProductModel.EMBED_DISCOUNTED_TAXED_PRICE]: Monetary | null
    [ProductModel.EMBED_CUSTOMER_PRICE]: Monetary | null
    [ProductModel.EMBED_CUSTOMER_TAXED_PRICE]: Monetary | null
    [ProductModel.EMBED_PRICES]: Monetary | null
    [ProductModel.EMBED_DEFAULT_IMAGE_ID]: number | null
    [ProductModel.EMBED_DEFAULT_IMAGE_URL]: string | null
    [ProductModel.EMBED_SECONDARY_IMAGE_ID]: number | null
    [ProductModel.EMBED_SECONDARY_IMAGE_URL]: string | null
    [ProductModel.EMBED_OG_IMAGE_URL]: string | null
    [ProductModel.EMBED_CATEGORY_IDS]: number[]
    [ProductModel.EMBED_PROPERTIES]: ProductProperty[]
    [ProductModel.EMBED_URLS]: Translation<string> | null
    [ProductModel.EMBED_TAGS]: ProductTag[]
    [ProductModel.EMBED_TAG_IDS]: number[] | null
    [ProductModel.EMBED_RESERVATION_EXPIRATION_AT]: ApiDateTime | null
    [ProductModel.EMBED_DISCOUNT_PERCENTS]: number | null
    [ProductModel.EMBED_BRAND]: Brand
    [ProductModel.EMBED_DEFAULT_CATEGORY]: Category
    [ProductModel.EMBED_PRODUCT_AVAILABILITY]: ProductAvailabilityModel
    [ProductModel.EMBED_ADDITIONAL_DATA]: number | null
    [ProductModel.EMBED_EXPORT_PRODUCT_IDS]: number[] | null
    [ProductModel.EMBED_ATTACHMENTS]: AttachmentModel[]
    [ProductModel.EMBED_ACTIVE_PRODUCT_VARIATIONS_COUNT]: number
    [ProductModel.EMBED_PRODUCT_VARIATION_PROPERTIES]: ProductVariationPropertyModel[]
    [ProductModel.EMBED_PRODUCT_VARIATIONS]: ProductVariationModel[]
    [ProductModel.EMBED_RATING]: number
}

export class ProductModel extends ApiModel<Attributes, Embeds> {
    static readonly ATTR_ID = 'id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_SHORT_DESCRIPTION = 'short_description'
    static readonly ATTR_DESCRIPTION = 'description'
    static readonly ATTR_META_TITLE = 'meta_title'
    static readonly ATTR_META_H1_TITLE = 'meta_h1_title'
    static readonly ATTR_META_DESCRIPTION = 'meta_description'
    static readonly ATTR_SLUG = 'slug'
    static readonly ATTR_BRAND_ID = 'brand_id'
    static readonly ATTR_PRODUCT_AVAILABILITY_ID = 'product_availability_id'
    static readonly ATTR_TAX_GROUP_ID = 'tax_group_id'
    static readonly ATTR_DEFAULT_CATEGORY_ID = 'default_category_id'
    static readonly ATTR_CODE = 'code'
    static readonly ATTR_SKU = 'sku'
    static readonly ATTR_EAN = 'ean'
    static readonly ATTR_UPC = 'upc'
    static readonly ATTR_JAN = 'jan'
    static readonly ATTR_ISBN = 'isbn'
    static readonly ATTR_MPN = 'mpn'
    static readonly ATTR_UPDATE_STOCK = 'update_stock'
    static readonly ATTR_STOCK_STATE = 'stock_state'
    static readonly ATTR_RESERVED_STATE = 'reserved_state'
    static readonly ATTR_POSITION = 'position'
    static readonly ATTR_HAS_AUTOMATIC_SLUG = 'has_automatic_slug'
    static readonly ATTR_IS_ACTIVE = 'is_active'
    static readonly ATTR_META_INDEX = 'meta_index'
    static readonly ATTR_META_FOLLOW = 'meta_follow'
    static readonly ATTR_SHOW_ON_SITEMAP = 'show_on_sitemap'
    static readonly ATTR_HEIGHT = 'height'
    static readonly ATTR_WIDTH = 'width'
    static readonly ATTR_DEPTH = 'depth'
    static readonly ATTR_WEIGHT = 'weight'
    static readonly ATTR_VOLUME = 'volume'
    static readonly ATTR_SELLS_COUNT = 'sells_count'
    static readonly ATTR_PRICE = 'price'
    static readonly ATTR_TAXED_PRICE = 'taxed_price'
    static readonly ATTR_TAX_RATE = 'tax_rate'
    static readonly ATTR_HAS_VARIATIONS = 'has_variations'
    static readonly ATTR_OG_IMAGE_ID = 'og_image_id'
    static readonly ATTR_OG_TITLE = 'og_title'
    static readonly ATTR_OG_DESCRIPTION = 'og_description'
    static readonly ATTR_EXPECTED_COUNT = 'expected_count'
    static readonly ATTR_EXPECTED_DATE_MAX = 'expected_date_max'
    static readonly ATTR_EXPECTED_DATE_MIN = 'expected_date_min'
    static readonly ATTR_PURCHASABLE_SINCE = 'purchasable_since'
    static readonly ATTR_MAX_PURCHASABLE_AMOUNT = 'max_purchasable_amount'
    static readonly ATTR_NEED_PACKAGE = 'need_package'
    static readonly ATTR_IS_HIDDEN = 'is_hidden'
    static readonly ATTR_AMOUNT_UNIT = 'amount_unit'
    static readonly ATTR_EXPECTED_DAYS = 'expected_days'
    static readonly ATTR_FEED_AVAILABILITY = 'feed_availability'

    static readonly EMBED_COMPUTED_PRICES = 'computed_prices'
    static readonly EMBED_COMPUTED_TAXED_PRICES = 'computed_taxed_prices'
    static readonly EMBED_STOCK_STATES = 'stock_states'
    static readonly EMBED_DISCOUNTED_PRICE = 'discounted_price'
    static readonly EMBED_DISCOUNTED_TAXED_PRICE = 'discounted_taxed_price'
    static readonly EMBED_CUSTOMER_PRICE = 'customer_price'
    static readonly EMBED_CUSTOMER_TAXED_PRICE = 'customer_taxed_price'
    static readonly EMBED_PRICES = 'prices'
    static readonly EMBED_DEFAULT_IMAGE_ID = 'default_image_id'
    static readonly EMBED_DEFAULT_IMAGE_URL = 'default_image_url'
    static readonly EMBED_SECONDARY_IMAGE_ID = 'secondary_image_id'
    static readonly EMBED_SECONDARY_IMAGE_URL = 'secondary_image_url'
    static readonly EMBED_OG_IMAGE_URL = 'og_image_url'
    static readonly EMBED_CATEGORY_IDS = 'category_ids'
    static readonly EMBED_PROPERTIES = 'properties'
    static readonly EMBED_URLS = 'urls'
    static readonly EMBED_TAGS = 'tags'
    static readonly EMBED_TAG_IDS = 'tag_ids'
    static readonly EMBED_RESERVATION_EXPIRATION_AT = 'reservation_expiration_at'
    static readonly EMBED_DISCOUNT_PERCENTS = 'discount_percents'
    static readonly EMBED_BRAND = 'brand'
    static readonly EMBED_DEFAULT_CATEGORY = 'default_category'
    static readonly EMBED_ACTIVE_PRODUCT_VARIATIONS_COUNT = 'active_product_variations_count'
    static readonly EMBED_PRODUCT_VARIATION_PROPERTIES = 'product_variation_properties'
    static readonly EMBED_PRODUCT_VARIATIONS = 'product_variations'
    static readonly EMBED_RATING = 'rating'

    /**
     * Used for showing translated product availability label
     */
    static readonly EMBED_PRODUCT_AVAILABILITY = 'product_availability'
    static readonly EMBED_ADDITIONAL_DATA = 'additional_data'
    static readonly EMBED_EXPORT_PRODUCT_IDS = 'export_product_ids'
    static readonly EMBED_ATTACHMENTS = 'attachments'

    get id() {
        return this._getAttribute(ProductModel.ATTR_ID)
    }

    get name() {
        return this._getAttribute(ProductModel.ATTR_NAME)
    }

    get shortDescription() {
        return this._getAttribute(ProductModel.ATTR_SHORT_DESCRIPTION)
    }

    get description() {
        return this._getAttribute(ProductModel.ATTR_DESCRIPTION)
    }

    get metaTitle() {
        return this._getAttribute(ProductModel.ATTR_META_TITLE)
    }

    get metaH1Title() {
        return this._getAttribute(ProductModel.ATTR_META_H1_TITLE)
    }

    get metaDescription() {
        return this._getAttribute(ProductModel.ATTR_META_DESCRIPTION)
    }

    get slug() {
        return this._getAttribute(ProductModel.ATTR_SLUG)
    }

    get brandId() {
        return this._getAttribute(ProductModel.ATTR_BRAND_ID)
    }

    private get productAvailabilityId() {
        return this._getAttribute(ProductModel.ATTR_PRODUCT_AVAILABILITY_ID)
    }

    get taxGroupId() {
        return this._getAttribute(ProductModel.ATTR_TAX_GROUP_ID)
    }

    get defaultCategoryId() {
        return this._getAttribute(ProductModel.ATTR_DEFAULT_CATEGORY_ID)
    }

    get code() {
        return this._getAttribute(ProductModel.ATTR_CODE)
    }

    private get sku() {
        return this._getAttribute(ProductModel.ATTR_SKU)
    }

    get ean() {
        return this._getAttribute(ProductModel.ATTR_EAN)
    }

    get upc() {
        return this._getAttribute(ProductModel.ATTR_UPC)
    }

    get jan() {
        return this._getAttribute(ProductModel.ATTR_JAN)
    }

    get isbn() {
        return this._getAttribute(ProductModel.ATTR_ISBN)
    }

    get mpn() {
        return this._getAttribute(ProductModel.ATTR_MPN)
    }

    get updateStock() {
        return this._getAttribute(ProductModel.ATTR_UPDATE_STOCK)
    }

    private get stockState() {
        return this._getAttribute(ProductModel.ATTR_STOCK_STATE)
    }

    private get reservedState() {
        return this._getAttribute(ProductModel.ATTR_RESERVED_STATE)
    }

    get position() {
        return this._getAttribute(ProductModel.ATTR_POSITION)
    }

    get hasAutomaticSlug() {
        return this._getAttribute(ProductModel.ATTR_HAS_AUTOMATIC_SLUG)
    }

    get isActive() {
        return this._getAttribute(ProductModel.ATTR_IS_ACTIVE)
    }

    get metaIndex() {
        return this._getAttribute(ProductModel.ATTR_META_INDEX)
    }

    get metaFollow() {
        return this._getAttribute(ProductModel.ATTR_META_FOLLOW)
    }

    get showOnSitemap() {
        return this._getAttribute(ProductModel.ATTR_SHOW_ON_SITEMAP)
    }

    get height() {
        return this._getAttribute(ProductModel.ATTR_HEIGHT)
    }

    get width() {
        return this._getAttribute(ProductModel.ATTR_WIDTH)
    }

    get depth() {
        return this._getAttribute(ProductModel.ATTR_DEPTH)
    }

    get weight() {
        return this._getAttribute(ProductModel.ATTR_WEIGHT)
    }

    get volume() {
        return this._getAttribute(ProductModel.ATTR_VOLUME)
    }

    get sellsCount() {
        return this._getAttribute(ProductModel.ATTR_SELLS_COUNT)
    }

    private get price() {
        return this._getAttribute(ProductModel.ATTR_PRICE)
    }

    private get taxedPrice() {
        return this._getAttribute(ProductModel.ATTR_TAXED_PRICE)
    }

    get taxRate() {
        return this._getAttribute(ProductModel.ATTR_TAX_RATE)
    }

    get hasVariations() {
        return this._getAttribute(ProductModel.ATTR_HAS_VARIATIONS)
    }

    get ogImageId() {
        return this._getAttribute(ProductModel.ATTR_OG_IMAGE_ID)
    }

    get ogTitle() {
        return this._getAttribute(ProductModel.ATTR_OG_TITLE)
    }

    get ogDescription() {
        return this._getAttribute(ProductModel.ATTR_OG_DESCRIPTION)
    }

    get expectedCount() {
        return this._getAttribute(ProductModel.ATTR_EXPECTED_COUNT)
    }

    get expectedDateMax() {
        return this._getAttribute(ProductModel.ATTR_EXPECTED_DATE_MAX)
    }

    get expectedDateMin() {
        return this._getAttribute(ProductModel.ATTR_EXPECTED_DATE_MIN)
    }

    get purchasableSince() {
        return this._getAttribute(ProductModel.ATTR_PURCHASABLE_SINCE)
    }

    private get maxPurchasableAmount() {
        return this._getAttribute(ProductModel.ATTR_MAX_PURCHASABLE_AMOUNT)
    }

    get needPackage() {
        return this._getAttribute(ProductModel.ATTR_NEED_PACKAGE)
    }

    get isHidden() {
        return this._getAttribute(ProductModel.ATTR_IS_HIDDEN)
    }

    private get amountUnit() {
        return this._getAttribute(ProductModel.ATTR_AMOUNT_UNIT)
    }

    get expectedDays() {
        return this._getAttribute(ProductModel.ATTR_EXPECTED_DAYS)
    }

    get feedAvailability() {
        return this._getAttribute(ProductModel.ATTR_FEED_AVAILABILITY)
    }

    private get computedPrices() {
        return this._getEmbed(ProductModel.EMBED_COMPUTED_PRICES)
    }

    private get computedTaxedPrices() {
        return this._getEmbed(ProductModel.EMBED_COMPUTED_TAXED_PRICES)
    }

    private get stockStates() {
        return this._getEmbed(ProductModel.EMBED_STOCK_STATES)
    }

    private get discountedPrice() {
        return this._getEmbed(ProductModel.EMBED_DISCOUNTED_PRICE)
    }

    private get discountedTaxedPrice() {
        return this._getEmbed(ProductModel.EMBED_DISCOUNTED_TAXED_PRICE)
    }

    private get customerPrice() {
        return this._getEmbed(ProductModel.EMBED_CUSTOMER_PRICE)
    }

    private get customerTaxedPrice() {
        return this._getEmbed(ProductModel.EMBED_CUSTOMER_TAXED_PRICE)
    }

    private get prices() {
        return this._getEmbed(ProductModel.EMBED_PRICES)
    }

    get defaultImageId() {
        return this._getEmbed(ProductModel.EMBED_DEFAULT_IMAGE_ID)
    }

    get defaultImageUrl() {
        return this._getEmbed(ProductModel.EMBED_DEFAULT_IMAGE_URL)
    }

    get secondaryImageId() {
        return this._getEmbed(ProductModel.EMBED_SECONDARY_IMAGE_ID)
    }

    get secondaryImageUrl() {
        return this._getEmbed(ProductModel.EMBED_SECONDARY_IMAGE_URL)
    }

    get ogImageUrl() {
        return this._getEmbed(ProductModel.EMBED_OG_IMAGE_URL)
    }

    get categoryIds() {
        return this._getEmbed(ProductModel.EMBED_CATEGORY_IDS)
    }

    get properties() {
        return this._getEmbed(ProductModel.EMBED_PROPERTIES)
    }

    private get urls() {
        return this._getEmbed(ProductModel.EMBED_URLS)
    }

    get tags() {
        return this._getEmbed(ProductModel.EMBED_TAGS)
    }

    get tagIds() {
        return this._getEmbed(ProductModel.EMBED_TAG_IDS)
    }

    get reservationExpirationAt() {
        return this._getEmbed(ProductModel.EMBED_RESERVATION_EXPIRATION_AT)
    }

    private get discountPercents() {
        return this._getEmbed(ProductModel.EMBED_DISCOUNT_PERCENTS)
    }

    get brand() {
        return this._getEmbed(ProductModel.EMBED_BRAND)
    }

    get defaultCategory() {
        return this._getEmbed(ProductModel.EMBED_DEFAULT_CATEGORY)
    }

    private get productAvailability() {
        return this._getEmbed(ProductModel.EMBED_PRODUCT_AVAILABILITY, ProductAvailabilityModel)
    }

    get additionalData() {
        return this._getEmbed(ProductModel.EMBED_ADDITIONAL_DATA)
    }

    get exportProductIds() {
        return this._getEmbed(ProductModel.EMBED_EXPORT_PRODUCT_IDS)
    }

    get attachments() {
        return this._getEmbed(ProductModel.EMBED_ATTACHMENTS, AttachmentModel)
    }

    get activeProductVariationsCount() {
        return this._getEmbed(ProductModel.EMBED_ACTIVE_PRODUCT_VARIATIONS_COUNT)
    }

    private get productVariationProperties() {
        return this._getEmbed(ProductModel.EMBED_PRODUCT_VARIATION_PROPERTIES, ProductVariationPropertyModel)
    }

    get productVariations() {
        return this._getEmbed(ProductModel.EMBED_PRODUCT_VARIATIONS, ProductVariationModel)
    }

    get rating() {
        return this._getEmbed(ProductModel.EMBED_RATING)
    }

    // ---------------------------------------------------------------------------------------------------------------------

    /**
     * Get the name of the product or its variation (if specified).
     * @param variation the variation to get the name from instead of the product (optional)
     */
    getName(variation: ProductVariationModel | null = null): string | null {
        return variation ? variation.name : this.name
    }

    /**
     * Get the price of the price object of the product or its variation (if specified).
     * The price is **WITH VAT** and **WITHOUT discounts**.
     *
     * Consider it as the base price of the product.
     * @param variation the variation to get the price from instead of the product (optional)
     * @see getFormattedPrice
     */
    getPrice(variation: ProductVariationModel | null = null): Monetary | null {
        if (variation) return variation.computedTaxedPrices?.taxed_price ?? null
        return this.computedTaxedPrices?.taxed_price ?? null
    }

    /**
     * Get the SKU of the product or its variation (if specified).
     * @param variation the variation to get the SKU from instead of the product (optional)
     */
    getSku(variation: ProductVariationModel | null = null): string | null {
        return variation ? variation.sku : this.sku
    }

    /**
     * Get the formatted price of the product or its variation (if specified).
     * The formatted price is a string with the currency symbol. (e.g. '$10.00')
     *
     * The price is **WITH VAT** and **WITHOUT discounts**.
     *
     * Consider it as the base price of the product.
     * @param variation
     * @see getPrice
     */
    getFormattedPrice(variation: ProductVariationModel | null = null): string | null {
        return getFormattedPrice(this.getPrice(variation))
    }

    /**
     * Get the discounted price of the product or its variation (if specified).
     * The price is **WITH VAT** and **WITH discounts**.
     *
     * @param variation the variation to get the price from instead of the product (optional)
     * @see getFormattedDiscountedPrice
     */
    getDiscountedPrice(variation: ProductVariationModel | null = null): Monetary | null {
        if (variation) return variation.computedTaxedPrices?.discounted_taxed_price ?? null
        return this.computedTaxedPrices?.discounted_taxed_price ?? null
    }

    /**
     * Get the formatted discounted price of the product or its variation (if specified).
     * The formatted price is a string with the currency symbol. (e.g. '$10.00')
     *
     * The price is **WITH VAT** and **WITH discounts**.
     *
     * @param variation the variation to get the price from instead of the product (optional)
     * @see getDiscountedPrice
     */
    getFormattedDiscountedPrice(variation: ProductVariationModel | null = null): string | null {
        return getFormattedPrice(this.getDiscountedPrice(variation))
    }

    /**
     * Checks whether the product or its variation (if specified) is discounted.
     * @param variation
     */
    isDiscounted(variation: ProductVariationModel | null = null): boolean {
        return (getPriceValue(this.getDiscountedPrice(variation)) ?? 0) < (getPriceValue(this.getPrice(variation)) ?? 0)
    }

    /**
     * Get the percentage of the discount of the product or its variation (if specified).
     * @param variation the variation to get the discount from instead of the product (optional)
     */
    getDiscountPercents(variation: ProductVariationModel | null = null): number | null {
        return variation ? variation.discountPercents : this.discountPercents
    }

    /**
     * Whether the product or its variation (if specified) is available or not.
     * Being available doesn't necessarily mean that the product can be added to the cart & purchased.
     * To determine whether the product can be purchased, use `canBePurchased()` instead.
     *
     * @param variation the variation to get the availability state from instead of the product (optional)
     * @returns `true` if the product is available according to its set availability or when no availability
     * is set, `false` otherwise.
     * @see canBePurchased
     */
    isAvailable(variation: ProductVariationModel | null = null): boolean {
        return (variation ? variation.productAvailability?.canPurchase : this.productAvailability?.canPurchase) ?? true
    }

    /**
     * Whether the product or its variation (if specified) can be **purchased**.
     *
     * If the product has variations, the availability of the product is determined by the availability of its variations.
     * (At least one variation should be available for the product to be available)
     *
     * !! Note !!
     * This method is different from `isAvailable` which only
     * returns the state of the product availability without checking the stock state.
     *
     * @param variation the variation to check instead (optional)
     * @see isAvailable
     * @see hasAvailability
     */
    canBePurchased(variation: ProductVariationModel | null = null): boolean {
        const hasStock = variation
            ? ((variation.stockState ?? 0) > 0)
            : ((this.stockState ?? 0) > 0)
        const updateStock = variation ? variation.updateStock : this.updateStock
        return this.isAvailable(variation) && (!updateStock || hasStock)
    }

    /**
     * Whether the add to cart button should be disabled for the product or its variation (if specified).
     * @param variation the variation to check the button state for (optional)
     */
    isAddToCartButtonDisabled(variation: ProductVariationModel | null = null): boolean {
        if (this.hasVariations && !variation) return true
        return !this.canBePurchased(variation)

    }

    /**
     * Whether the product or its variation (if specified) has its availability specified.
     * @param variation
     */
    hasAvailability(variation: ProductVariationModel | null = null): boolean {
        return variation ? !!variation.productAvailability : !!this.productAvailability
    }

    /**
     * Get the availability label of the product or its variation (if specified).
     * @param variation the variation to get the availability label from instead of the product (optional)
     */
    getAvailabilityLabel(variation: ProductVariationModel | null = null): string | null {
        return variation ? variation.productAvailability?.name ?? null : this.productAvailability?.name ?? null
    }

    /**
     * Get the maximum amount of the product or its variation (if specified) that can be added to the cart.
     *
     * _Note that this is different from the model attribute with a similar name._
     * @param variation the variation to get the max purchasable amount from instead of the product (optional)
     */
    getMaxPurchasableAmount(variation: ProductVariationModel | null = null): number | null {
        const maxPurchasableAmount = variation ? variation.maxPurchasableAmount : this.maxPurchasableAmount
        const stockState = variation ? variation.stockState : this.stockState
        const updateStock = variation ? variation.updateStock : this.updateStock

        return updateStock ? Math.min(maxPurchasableAmount ?? Infinity, stockState ?? 0) : null
    }

    /**
     * Get the product properties that are common for all variations.
     */
    getCommonProperties() {
        return this.properties?.filter(property => !property.is_for_variation) ?? []
    }

    /**
     * Get the product properties that differentiate the variations. (the ones that
     * should appear in the variation selector)
     */
    getVariationProperties() {
        return this.productVariationProperties ?? []
    }

    /**
     * Whether the product or its variation (if specified) has stock count set
     * and is not out of stock.
     * @param variation the variation to check the stock state for (optional)
     */
    hasStock(variation: ProductVariationModel | null = null): boolean {
        const stockState = variation ? variation.stockState : this.stockState
        const updateStock = variation ? variation.updateStock : this.updateStock
        return !updateStock || (stockState !== null && stockState > 0)
    }

    /**
     * Get the formatted stock count with translated amount unit.
     * In case the stock count is not set, `null` is returned.
     * @param variation the variation to get the stock state from instead of the product (optional)
     */
    getFormattedStockCount(variation?: ProductVariationModel | null) {
        const stockCount = variation ? variation.stockState : this.stockState
        const updateStock = variation ? variation.updateStock : this.updateStock
        return stockCount && updateStock ? `${stockCount} ${useT(`labels.unit.${this.amountUnit}`)}` : null
    }

    /**
     * Get the URL of the product.
     * @todo Implement handling for the case when the embed is a translation object
     */
    getUrl() {
        return typeof this.urls === 'string' ? this.urls : null
    }

    /**
     * Get the default image URL of the product.
     * In case no default image is set, the first product image URL according to
     * the order of the images is returned. (secondary image)
     */
    getHeroImage() {
        return this.defaultImageUrl ?? this.secondaryImageUrl ?? null
    }
}

// =====================================================================================================================
// TYPESCRIPT TYPE DECLARATIONS
// =====================================================================================================================

interface ProductProperty {
    product_property_id: number
    product_property_attribute_id: number
    is_for_variation: boolean
    attribute_name: string | null
    property_name: string | null
    value: unknown
}

interface ProductTag {
    id: number
    label: string | null
    type: number
    has_rules: boolean
    is_active: boolean
    validity: ApiNullableDateTimeRange
    position: number
    text_color: string | null
    background_color: string | null
    updated_at: ApiDateTime
    created_at: ApiDateTime
}

interface Brand {
    id: number
    name: string | null
    description: string | null
    image_id: number | null
    meta_title: string | null
    meta_description: string | null
    slug: string | null
    is_active: boolean
    has_automatic_slug: boolean
    meta_index: boolean
    meta_follow: boolean

    show_on_sitemap: boolean
    created_at: ApiDateTime
}

interface Category {
    id: number
    name: string | null
    description: string | null
    parent_id: number | null
    image_id: number | null
    thumbnail_image_id: number | null
    meta_title: string | null
    meta_description: string | null
    slug: string | null
    position: number
    available_for_languages: string[]
    is_active: boolean
    has_automatic_slug: boolean
    meta_index: boolean
    meta_follow: boolean
    show_on_sitemap: boolean
    customer_groups_restriction: boolean
    created_ad: ApiDateTime
}

interface ProductStockState {
    state: number
    stock_id: number
}
