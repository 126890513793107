import routerOptions0 from "/opt/simploshop/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._qmj4hv4ysdxaferpnceonlpv7a/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/opt/simploshop/shared/core-theme/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}