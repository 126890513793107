import { Locale } from '../enums/i18n'
// Y-m-d
export type ApiDate = string
// Y-m-d H:i:s
export type ApiDateTime = string
// ISO 639-1 Code unique for single language (en) - used in HTML and for the selected i18n locale
export type LangID = keyof typeof ApiLanguageCodesMap
// ISO 639-2/B Code unique for single language (eng) - used in the API
export type ApiLangID = typeof ApiLanguageCodesMap[LangID]

export const ApiLanguageCodesMap  = {
    [Locale.CS]: 'ces',
    [Locale.SK]: 'slk',
    [Locale.EN]: 'eng',
} as const

export interface ApiDateTimeRange {
    from: ApiDateTime
    to: ApiDateTime
}

export interface ApiNullableDateTimeRange {
    from: ApiDateTime | null
    to: ApiDateTime | null
}

export interface ApiFieldError {
    // Name of the input field
    field: string
    // Human-readable description of the problem, can be localized
    message: string
}

export interface Language {
    // language identifier
    id: ApiLangID
    // localized name of the language
    name: string
    // the native name of the language
    original_name: string | null
    // inactive languages are not show on e-shops nor required in input
    is_active: boolean
    // default language is used as a fallback
    is_default: boolean
    created_at: ApiDateTime
}

export type Translation<T = string> = {
    [key in ApiLangID]?: T | null
} | T | null

export type FormattedCurrency = { value: number, formatted: string }

type MonetaryValue = number | FormattedCurrency | null

export type Monetary = {
    [currency_id: string]: MonetaryValue
} | MonetaryValue

export type MonetaryPerRate = {
    amount: Monetary
    tax_rate: number
}[]

export interface ComputedPrices {
    taxed_price: Monetary
    discounted_taxed_price: Monetary
}

export type Length = {
    unit: string
    value: number
}

export type Weight = {
    unit: string
    value: number
}

export type Volume = {
    unit: string
    value: number
}
