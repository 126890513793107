import { toast } from 'vue-sonner'

interface NotifyOptions {

}

interface NotifyMessage {
    title: string
    text: string
}

export function useNotifications() {
    const { $i18n } = useNuxtApp()

    function notifyError(message: string | NotifyMessage | undefined | null = undefined) {
        const title = typeof message === 'string' ? message : message?.title ?? $i18n.t('_core_simploshop.labels.error')
        const description = typeof message === 'string' ? undefined : message?.text ?? $i18n.t('_core_simploshop.labels.unknownError')

        toast.error(title, {
            description: description,
        })
    }

    function notifySuccess(message: string | NotifyMessage | undefined | null = undefined) {
        const title = typeof message === 'string' ? message : message?.title ?? $i18n.t('_core_simploshop.labels.success')
        const description = typeof message === 'string' ? undefined : message?.text

        toast.success(title, {
            description: description,
            duration: 5000,
        })
    }

    function notify(message: string | NotifyMessage, options?: Partial<NotifyOptions>) {
        const title = typeof message === 'string' ? message : message.title
        const description = typeof message === 'string' ? undefined : message.text

        toast(title, {
            description: description,
        })
    }

    return {
        notifyError,
        notifySuccess,
        notify,
    }
}

/**
 * Shows an error notification.
 * If the message value is nullish, an 'unknown error' message is shown.
 * @param message
 */
export function useNotifyError(message: string | NotifyMessage | undefined | null = undefined) {
    const notifications = useNotifications()
    notifications.notifyError(message)
}

export function useNotifySuccess(message: string | NotifyMessage | undefined | null = undefined) {
    const notifications = useNotifications()
    notifications.notifySuccess(message)

}

export function useNotify(message: string | NotifyMessage, options?: Partial<NotifyOptions>) {
    const notifications = useNotifications()
    notifications.notify(message, options)
}
