import { ApiModel } from '@composable-api/api.model'

interface Attributes {
    [ProductReviewModel.ATTR_ID]: number
    [ProductReviewModel.ATTR_PRODUCT_ID]: number
    [ProductReviewModel.ATTR_CUSTOMER_ID]: number | null
    [ProductReviewModel.ATTR_COMMENT]: string
    [ProductReviewModel.ATTR_RATING]: number
    [ProductReviewModel.ATTR_CREATED_AT]: string
    [ProductReviewModel.ATTR_NAME]: string
}

interface Embeds {

}

export class ProductReviewModel extends ApiModel<Attributes, Embeds> {
    static readonly ATTR_ID = 'id'
    static readonly ATTR_CUSTOMER_ID = 'customer_id'
    static readonly ATTR_PRODUCT_ID = 'product_id'
    static readonly ATTR_NAME = 'name'
    static readonly ATTR_COMMENT = 'comment'
    static readonly ATTR_RATING = 'rating'
    static readonly ATTR_CREATED_AT = 'created_at'

    get id() {
        return this._getAttribute(ProductReviewModel.ATTR_ID)
    }

    get customerId() {
        return this._getAttribute(ProductReviewModel.ATTR_CUSTOMER_ID)
    }

    get productId() {
        return this._getAttribute(ProductReviewModel.ATTR_PRODUCT_ID)
    }

    get name() {
        return this._getAttribute(ProductReviewModel.ATTR_NAME)
    }

    get comment() {
        return this._getAttribute(ProductReviewModel.ATTR_COMMENT)
    }

    get rating() {
        return this._getAttribute(ProductReviewModel.ATTR_RATING)
    }

    get createdAt() {
        return this._getAttribute(ProductReviewModel.ATTR_CREATED_AT)
    }
}
