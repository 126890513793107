export const models = createClassesMap([
    MeModel,
    CustomerAddressModel,
    CustomerModel,
    CustomerSessionModel,
    OrderModel,
    AddressModel,
    BannerModel,
    NewsletterModel,
    ProductModel,
    UserSessionModel,
    OrderModel,
    ProductReviewModel,
    ProductVariationModel,
    AttachmentModel,
    ProductAttachmentModel,
    ProductAvailabilityModel,
    ProductImageModel,
    ProductRelatedProductModel,
    ProductVideoModel,
    StaticPageModel,
    CategoryModel,
    CategoryTreeModel,
    CustomerProductListModel,
    CustomerProductListItemModel,
    PageModel,
    CartModel,
    CartItemModel,
    CartDiscountModel,
    CartShippingMethodModel,
    CartPaymentMethodModel,
    CartAddressModel,
    FormattedCurrencyModel,
    ProductFiltersResponseModel,
    ProductFilterModel,
    ProductFilterItemModel,
    ProductFiltersPaginationModel,
    OrderItemModel,
    OrderAddressModel,
    CurrencyModel,
    CountryModel,
    ProductVariationPropertyModel,
    ProductVariationPropertyAttributeModel,
    ArticleModel,
    ArticleAuthorModel,
    ArticleCategoryModel,
    UserModel,
    CustomerPasswordModel,
    FreeShippingModel,
    ShopInfoModel,
    GoPayModel,
])

export default definePayloadPlugin(() => {
    definePayloadReducer('response', getApiResponsePayloadReducer())
    definePayloadReviver('response', getApiResponsePayloadReviver(models))
})
