import {
    useStateHeaders
} from '../private/state'
import type { Headers, ServerCache } from '../types/module'

/**
 * A composable used to configure the composable api module.
 */
export function useComposableApi() {
    const headers = useStateHeaders()

    function setHeader<K extends keyof Headers>(header: K, value: Headers[K] | null) {
        headers.value[header] = value as never
    }

    function getHeader<K extends keyof Headers>(header: K): Headers[K] | null {
        return headers.value[header] ?? null
    }

    return {
        setHeader,
        getHeader,
    }
}
