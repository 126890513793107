import api_response_error_serialization_AEPGpW8H3k from "/opt/simploshop/shared/modules/composable-api/src/runtime/plugins/api-response-error-serialization.ts";
import model_serialization_fJmJ1986BD from "/opt/simploshop/shared/core-theme/app/plugins/model-serialization.ts";
import response_serialization_nCl7CF5nGd from "/opt/simploshop/shared/core-theme/app/plugins/response-serialization.ts";
import revive_payload_client_DEekCwwN2S from "/opt/simploshop/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._qmj4hv4ysdxaferpnceonlpv7a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_JXUMWS8qLV from "/opt/simploshop/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._qmj4hv4ysdxaferpnceonlpv7a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_FzjoJcexb5 from "/opt/simploshop/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._qmj4hv4ysdxaferpnceonlpv7a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_FXFW38mL7i from "/opt/simploshop/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._qmj4hv4ysdxaferpnceonlpv7a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_qXS9xAxd2S from "/opt/simploshop/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._qmj4hv4ysdxaferpnceonlpv7a/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_9khiXvSVqw from "/opt/simploshop/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._qmj4hv4ysdxaferpnceonlpv7a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_5ph1JXRB6B from "/opt/simploshop/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._qmj4hv4ysdxaferpnceonlpv7a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_WgL80oweTy from "/opt/simploshop/node_modules/.pnpm/@pinia+nuxt@0.5.2_magicast@0.3.4_rollup@4.20.0_typescript@5.5.4_vue@3.4.35_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/simploshop/themes/theme/.nuxt/components.plugin.mjs";
import prefetch_client_sPoSHng8TD from "/opt/simploshop/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_eslint@9.8.0_ioredis@5.4.1_magicast@0.3._qmj4hv4ysdxaferpnceonlpv7a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_EfnqKeBX0u from "/opt/simploshop/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.20.0_vue@3.4.35_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_0ykMs2KriF from "/opt/simploshop/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.20.0_vue@3.4.35_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import lite_youtube_client_kDCiZBs6WY from "/opt/simploshop/shared/core-simploshop/app/plugins/lite-youtube.client.ts";
import v_visibility_N214iPkHWb from "/opt/simploshop/shared/core-simploshop/app/plugins/v-visibility.ts";
import sentry_client_oJ0WmhsZd2 from "/opt/simploshop/shared/core-theme/app/plugins/prod/sentry.client.ts";
import auth_soM0sFPTJb from "/opt/simploshop/shared/core-theme/app/plugins/auth.ts";
import global_events_client_f10UUQk6Lm from "/opt/simploshop/shared/core-theme/app/plugins/global-events.client.ts";
import properties_sGCK2d0mhj from "/opt/simploshop/shared/core-theme/app/plugins/properties.ts";
import setup_zrAIVq6G18 from "/opt/simploshop/shared/core-theme/app/plugins/setup.ts";
import routing_75roHpTH6G from "/opt/simploshop/themes/theme/app/plugins/routing.ts";
export default [
  api_response_error_serialization_AEPGpW8H3k,
  model_serialization_fJmJ1986BD,
  response_serialization_nCl7CF5nGd,
  revive_payload_client_DEekCwwN2S,
  unhead_JXUMWS8qLV,
  router_FzjoJcexb5,
  payload_client_FXFW38mL7i,
  navigation_repaint_client_qXS9xAxd2S,
  check_outdated_build_client_9khiXvSVqw,
  chunk_reload_client_5ph1JXRB6B,
  plugin_vue3_WgL80oweTy,
  components_plugin_KR1HBZs4kY,
  prefetch_client_sPoSHng8TD,
  switch_locale_path_ssr_EfnqKeBX0u,
  i18n_0ykMs2KriF,
  lite_youtube_client_kDCiZBs6WY,
  v_visibility_N214iPkHWb,
  sentry_client_oJ0WmhsZd2,
  auth_soM0sFPTJb,
  global_events_client_f10UUQk6Lm,
  properties_sGCK2d0mhj,
  setup_zrAIVq6G18,
  routing_75roHpTH6G
]